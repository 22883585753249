

@media (max-width:1000px ) and (min-width:600px ) {
	.testclass{
		margin-top: 120px;
	}
	.profile-pages-top{
		margin-top: 0px;
	}
	.message-pages-top{
		margin-top: 80px;
	}
	.wrapper {
		display: flex;
		min-height: calc(100vh - 138px);
		padding-top: 0px !important;
	}
}
@media (max-width:600px) {
	.wrapper {
	
		min-height: calc(100vh - 138px);
		padding-top: 0px !important;
	}
	.testclass{
		margin-top: 110px;
	}
	.profile-pages-top{
		margin-top: 0px;
	}
	.message-pages-top{
		margin-top: 80px;
	}
	.content {
		width: 100% !important;
		padding: 20px 30px !important;
		display: block  !important;
    	gap: 0px !important;
	}
	
	.create-post-features {
		display: block !important;
		align-items: center;
	}
	.dropdowncustom {
		margin-right: 0px !important;
		/* line-height: 0.5 !important; */
	}
	.mobile-span-top{
		padding-top: 0px !important;
    height: 13px;
    display: flex;
		
	}
	.custom-file-inputnew {
		width: 100% !important;
	}
	.mobile-post-btn{
		margin-left: 20px;
		
	}

}

.wrapper {
	display: flex;
	min-height: calc(100vh - 138px);
	padding-top: 100px;
}
.side-bar {
	/* width: 20%; */
	background: #f6f9fe;
}
.sidebar-list li {
}
.sidebar-list li a {
	width: 100%;
	padding: 15px 25px;
}
.sidebar-list li a.active {
	background: #08357a;
}
.sidebar-list li a.active {
	color: #ffffff;
}
.sidebar-list li a.active .dark-icon {
	display: none;
}
.sidebar-list li a.active .light-icon {
	display: inline-block;
}
.sidebar-list li span:first-child {
	margin-right: 15px;
}
.sidebar-list li:hover {
	background: #08357a;
}
.sidebar-list li:hover a {
	color: #ffffff;
}
.sidebar-list li:hover .dark-icon {
	display: none;
}
.sidebar-list li:hover .light-icon {
	display: inline-block;
}
.sidebar-list li a {
	font-weight: 500;
	font-size: 16px;
	color: #464646;
	text-decoration: none;
	display: flex;
	align-items: center;
	font-family: "Raleway", sans-serif;
}
.sidebar-list li a span {
	font-family: "Raleway", sans-serif;
}
.sidebar-list li .light-icon {
	display: none;
}
.content {
	width: 85%;
	padding: 20px 30px;
	display: flex;
	gap: 25px;
}
.feed-main {
	flex-basis: 75%;
}
.feed-main-social{
    flex-basis: 80%;
}

.create-post {
	display: flex;
	gap: 10px;
	align-items: flex-start;
	padding: 15px;
	background: #ffffff;
	border: 1px solid #c0dbff;
	box-shadow: 0px 10px 40px rgba(222, 230, 237, 0.4);
	margin-bottom: 20px;
}
.post-profile {
	flex-basis: 7%;
}
.create-post-form {
	flex-basis: 91%;
}
.create-post-form .create-post-content {
	position: relative;
	margin-bottom: 20px;
}
.create-post-form .create-post-content input {
	width: 100%;
	padding: 10px 10px 10px 40px;
	border: none;
	outline: none;
	background: #f8fbff;
	box-shadow: inset 0px 4px 40px rgba(175, 193, 217, 0.12);
	border-radius: 8px;
	font-size: 16px;
	color: #90aacd;
	font-family: "Raleway", sans-serif;
}
.create-post-form .create-post-content textarea {
	width: 100%;
	padding: 10px 10px 10px 40px;
	border: none;
	outline: none;
	background: #f8fbff;
	box-shadow: inset 0px 4px 40px rgb(175 193 217 / 12%);
	border-radius: 8px;
	font-size: 16px;
	color: #90aacd;
	font-family: "Raleway", sans-serif;
}

.custom-file-inputnew {
    padding: 0px 3px !important;
    color: transparent;
	font-family: "Releway" sans-serif;
}
.custom-file-inputnew::-webkit-file-upload-button {
	visibility: hidden;
}
.custom-file-inputnew::before {
	content: "Add Photos";
    display: inline-block;
    color: #000;
    font-size: 14px !important;
    font-family: "Releway", sans-serif !important;
    border-radius: 3px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
}
.custom-file-inputnew:hover::before {
	border-color: black;
}
.custom-file-inputnew:active {
	outline: 0;
}



.custom-file-inputnew-location {
	width: 14%;
    padding: 5px 3px !important;
    color: transparent;
    margin-top: -6px;
}
.custom-file-inputnew-location::-webkit-file-upload-button {
	visibility: hidden;
}
.custom-file-inputnew-location::before {
	content: "Add Location";

	display: inline-block;
	color: #000;
	border-radius: 3px;
	outline: none;
	white-space: nowrap;
	-webkit-user-select: none;
	cursor: pointer;
	font-size: 14px !important;
    font-family: "Releway", sans-serif !important;
}
.custom-file-inputnew-location:hover::before {
	border-color: black;
}
.custom-file-inputnew-location:active {
	outline: 0;
}


.create-post-form .create-post-content input::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #90aacd;
	opacity: 1; /* Firefox */
}

.create-post-form .create-post-content input:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #90aacd;
}

.create-post-form .create-post-content input::-ms-input-placeholder {
	/* Microsoft Edge */
	color: #90aacd;
}
.create-post-form .create-post-content span {
	position: absolute;
	left: 8px;
	top: 6px;
	font-size: 20px;
	color: #90aacd;
}
.create-post-features {
	display: flex;
	align-items: center;
}
.create-post-features a {
	margin-right: 20px;
	font-weight: 500;
	font-size: 14px;
	color: #464646;
	text-decoration: none;
	display: flex;
	align-items: center;
	gap: 10px;
}
.create-post-features a span {
	font-family: "Raleway", sans-serif;
}
.added-post {
	padding: 15px;
	background: #ffffff;
	border: 1px solid #c0dbff;
	box-shadow: 0px 10px 40px rgba(222, 230, 237, 0.4);
	margin-bottom: 20px;
}
.added-post .added-post-user-info {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
}
.added-post-user-info .user-info {
	display: flex;
	align-items: center;
	gap: 10px;
}
.user-info .user-info-detail h2 {
	font-weight: 500;
	font-size: 14px;
	color: #1b1d28;
	padding-bottom: 5px;
	font-family: "Raleway", sans-serif;
}
.user-info .user-info-detail h5 {
	font-weight: 500;
	font-size: 10px;
	color: #90aacd;
	font-family: "Raleway", sans-serif;
}
.added-post-options .dropdown-toggle::after {
	border: none;
	content: url(../images//post-dropdown-icon.svg);
}
.added-post-options .dropdown-menu {
	left: -147% !important;
}
.added-post-description h3 {
	font-weight: 500;
	font-size: 14px;
	color: #1b1d28;
	font-family: "Raleway", sans-serif;
	line-height: 23px;
}
.added-post-description {
	margin-bottom: 10px;
}
.added-post-image {
	margin-bottom: 10px;
}
.added-post-image-more-grid {
	margin-bottom: 10px;
}
.right-grid-col {
	flex-basis: 50%;
}
.left-grid-col {
	flex-basis: 50%;
}
.left-grid-col div:first-child {
	padding-bottom: 20px;
}
.added-post-image-more-grid img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.added-post-image img {
	width: 100%;
	object-fit: cover;
}
.post-property-link a {
	font-family: "Raleway", sans-serif;
}
.like-comment-info {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
}
.like-comment-info .total-likes a {
	color: #ffffff;
	text-decoration: none;
	font-weight: 400;
	font-size: 10px;
	display: flex;
	align-items: center;
}
.like-comment-info .total-likes a span {
	margin-left: -10px;
}
.like-comment-info .total-likes a span:first-child {
	margin-left: 0;
}
.like-comment-info .total-likes a .total-counts {
	width: 22px;
	height: 22px;
	border-radius: 50%;
	background: #7a9bc4;
	display: flex;
	align-items: center;
	justify-content: center;
}
.total-comments {
	display: flex;
	gap: 25px;
}
.total-comments a {
	font-weight: 500;
	font-size: 14px;
	color: #707a89;
	text-decoration: none;
	position: relative;
	font-family: "Raleway", sans-serif;
}
.total-comments a:first-child::after {
	content: "";
	width: 5px;
	height: 5px;
	background: #464646;
	position: absolute;
	right: -16px;
	top: 6px;
	border-radius: 50%;
}
.do-like-comment {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-block: 15px;
	border-top: 0.8px solid #e4effe;
	border-bottom: 0.8px solid #e4effe;
	margin-bottom: 10px;
}
.do-like-comment a {
	display: flex;
	align-items: center;
	font-weight: 500;
	font-size: 12px;
	color: #707a89;
	text-decoration: none;
	gap: 10px;
	margin-right: 18px;
}
.do-like-comment a span {
	font-family: "Raleway", sans-serif;
}
.add-comment {
	display: flex;
	align-items: center;
	gap: 15px;
}
.comment-input {
	flex-basis: 93%;
}
.comment-input input {
	width: 100%;
	padding: 10px 10px 10px 10px;
	border: none;
	outline: none;
	background: #f8fbff;
	box-shadow: inset 0px 4px 40px rgba(175, 193, 217, 0.12);
	border-radius: 8px;
	font-size: 16px;
	color: #90aacd;
	font-family: "Raleway", sans-serif;
}
.comment-input input::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #90aacd;
	opacity: 1; /* Firefox */
}

.comment-input input:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #90aacd;
}

.comment-input input::-ms-input-placeholder {
	/* Microsoft Edge */
	color: #90aacd;
}
.submit-comment {
	flex-basis: 7%;
}
.submit-comment button {
	width: 100%;
	height: 100%;
	border: 1px solid #08357a;
	box-shadow: inset 0px 4px 40px rgba(175, 193, 217, 0.12);
	border-radius: 8px;
	outline: none;
	padding: 9.5px;
	background-color: transparent;
}
.notification-main {
	flex-basis: 35%;
}
.notification-main-social {
	flex-basis: 20%;
}
button.btn.active.profile-add-btn {
    font-weight: bold;
    background: #08357a;
    color: white;
}
.edit-profile-content-social {
    width: 100%;
    display: flex;
}
.edit-container-social {
    flex: 1 1;
    padding: 20px;
}
.editbtn{
	float: right;
	font-weight: bold;
	cursor: pointer;
}

.notification-card {
	padding: 15px;
	background: #ffffff;
	border: 1px solid #c0dbff;
	box-shadow: 0px 10px 40px rgba(222, 230, 237, 0.4);
	margin-bottom: 15px;
}
.notify-head {
	display: flex;
	justify-content: space-between;
	padding-bottom: 15px;
	border-bottom: 1px solid #c0dbff;
}
.notify-head h2 {
	font-weight: 600;
	font-size: 16px;
	color: #464646;
	font-family: "Raleway", sans-serif;
}
.notify-head a {
	font-weight: 600;
	font-size: 14px;
	color: #08357a;
	text-decoration: none;
	font-family: "Raleway", sans-serif;
}
.event-notify {
	display: flex;
	align-items: center;
	gap: 15px;
	margin-block: 15px;
}
.notify-user-detail h2 {
	font-weight: 700;
	font-size: 14px;
	color: #464646;
	padding-bottom: 7px;
	font-family: "Raleway", sans-serif;
}
.notify-user-detail h4 {
	font-weight: 400;
	font-size: 12px;
	color: #90aacd;
	font-family: "Raleway", sans-serif;
}
.event-detail-sub {
	display: flex;
	gap: 15px;
	padding: 15px;
	background: #f7f9fb;
	border: 1px solid #e6eefb;
	border-radius: 12px;
	margin-bottom: 10px;
}
.event-desc h2 {
	font-weight: 700;
	font-size: 14px;
	color: #464646;
	padding-bottom: 8px;
	font-family: "Raleway", sans-serif;
}
.event-desc h6 {
	line-height: 18px;
	color: black;
	font-weight: 400;
	font-size: 12px;
	color: #bdbdbd;
	font-family: "Raleway", sans-serif;
}
.event-person-count {
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
}
.event-person-count .total-persons {
	font-weight: 600;
	font-size: 12px;
	color: #464646;
	font-family: "Raleway", sans-serif;
}
.persons-profile span {
	margin-right: -10px;
}
.persons-profile span:last-child {
	margin-right: 0px;
}
.user-action-detail {
	display: flex;
	align-items: center;
	gap: 10px;
}
.notify-user-action h2 {
	font-weight: 600;
	font-size: 12px;
	padding-bottom: 7px;
	color: #707a89;
	font-family: "Raleway", sans-serif;
}
.notify-user-action h4 {
	font-weight: 500;
	font-size: 10px;
	color: #90aacd;
	font-family: "Raleway", sans-serif;
}
.user-status {
	text-align: right;
	flex-basis: 20%;
}
.user-status span {
	color: #28b775;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	display: inline-block;
	background-color: #28b775;
}
.user-request-info {
	display: flex;
	gap: 10px;
	margin-bottom: 10px;

}

.user-request-info-community {
	display: flex;
	gap: 10px;
	margin-bottom: 10px;

	border: 1px solid #e9e9e9;
    padding: 10px;
    border-radius: 2px;
}
.requested-user-detail h2 {
	padding-block: 10px;
	font-weight: 500;
	font-size: 14px;
	color: #707a89;
	font-family: "Raleway", sans-serif;
}
.requested-user-detail h4 {
	font-weight: 600;
	font-size: 14px;
	color: #464646;
	padding-block: 7px;
	font-family: "Raleway", sans-serif;
}
.requested-user-detail h5 {
	font-weight: 400;
	font-size: 12px;
	color: #90aacd;
	font-family: "Raleway", sans-serif;
}
.request-btns button {
	font-weight: 500;
	font-size: 14px;
	outline: none;
	padding: 10px 25px;
	border-radius: 6px;
	font-family: "Raleway", sans-serif;
}
.request-btns .ignore-btn {
	background: #ffffff;
	border: 1px solid #c0dbff;
	box-shadow: 0px 10px 40px rgba(222, 230, 237, 0.4);
	margin-right: 20px;
	color: #464646;
	font-family: "Raleway", sans-serif;
	margin-bottom: 5px;
}
.request-btns .follow-btn {
	background: #08357a;
	border-radius: 6px;
	border: none;
	color: #ffffff;
}
.people-card .notify-head {
	border: none;
}
.ppl-dropdwon .dropdown-toggle::after {
	border: none;
	content: url(../images//post-dropdown-icon.svg);
}
.people-suggestions {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.people-info-main {
	display: flex;
	gap: 10px;
	align-items: center;
	margin-bottom: 10px;
}
.people-info h4 {
	font-weight: 600;
	font-size: 14px;
	color: #464646;
	padding-bottom: 7px;
	font-family: "Raleway", sans-serif;
}
.people-info h5 {
	font-weight: 400;
	font-size: 12px;
	color: #90aacd;
	font-family: "Raleway", sans-serif;
}
.user-status small {
	font-weight: 600;
	font-size: 14px;
	color: #c3d0e2;
	font-family: "Raleway", sans-serif;
}

/* footer css  */

.footer {
	display: flex;
	justify-content: space-between;
	padding: 10px 20px;
	background: #ffffff;
	border: 1px solid #c0dbff;
	box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.25);
	align-items: center;
}
.footer-links ul {
	display: flex;
	gap: 30px;
}
.footer-links ul li {
	position: relative;
}
.footer-links ul li::after {
	content: "";
	width: 5px;
	height: 5px;
	background: #464646;
	position: absolute;
	right: -16px;
	top: 6px;
	border-radius: 50%;
}
.footer-links ul li:last-child::after {
	content: none;
}
.footer-links ul li a {
	font-weight: 500;
	font-size: 16px;
	color: #464646;
	text-decoration: none;
}

.social-link {
	display: flex;
	gap: 10px;
}
.copy-right h2 {
	font-weight: 500;
	font-size: 16px;
	color: #1b1d28;
}
.comment-list{
	background-color: #eeeeee;
    padding: 6px;
    width: 100%;
    margin: 0 3px 4px auto;
    margin-top: 5px;
    color: #afadad;
	border-radius: 5px;
}

.comment-list span {
	color: #000000;
    padding: 5px;
}
.comment-text{
	display: block;
    font-size: 12px;
    color: #000000 !important;
}
.comment-img{
	padding: 10px;
}
.dropdowncustom{
	color: rgb(0, 0, 0);
    background: rgb(255, 255, 255);
    border: none;
    padding-left: 5px !important;
    padding-right: 20px !important;
	font-size: 14px !important;
    font-family: "Releway", sans-serif !important;
	margin-right:20px
}
.hidecomments{
	display: none;
}
.do-like-innerdiv{
	display: flex;
	
}
.fontawosomiconcustom{
	font-size: 16px;
	color: #686868;
}

#nav-dropdown-dark-example-new::after {
    margin-left: 100%;
    margin-top: 0px;
}

.closecustom{
	font-size: 15px;
    cursor: pointer;
    height: 14px !important;
    width: 14px;
    align-items: center;
    padding: 0px;
    text-align: center !important;
}


.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    color: #000 !important;
    background-color: #fff !important;
    border-color: #fff !important;
}

.btn-primary.focus, .btn-primary:focus {
	color: #000 !important;
    background-color: #fff !important;
    border-color: #fff !important;
    box-shadow: 0 0 0 0.2rem rgb(38 143 255 / 50%);
}

.btn-primary:hover {
	color: #000 !important;
    background-color: #fff !important;
    border-color: #fff !important;
	box-shadow: 0 0 0 0.2rem rgb(38 143 255 / 50%);
}
.custom-file-inputnew:hover{
	box-shadow: 0 0 0 0.2rem rgb(38 143 255 / 50%);
}
.custom-file-inputnew-location:hover{
	box-shadow: 0 0 0 0.2rem rgb(38 143 255 / 50%);
}

.loader {
	position: absolute;
	top: calc(50% - 4em);
	left: calc(50% - 4em);
	width: 125px;
	height: 125px;
	border: 5px solid rgba(0, 0, 0, 0.2);
    border-left: 5px solid #000000;
	border-radius: 50%;
	animation: load8 1.1s infinite linear;
	transition: opacity 0.3s;
  }
  
  .loader--hide {
	opacity: 0;
  }
  
  @keyframes load8 {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  }

  #loading {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	opacity: 0.9;
	background-color: #fff;
	z-index: 99999;
  }
  
  #loading-image {
	z-index: 100;
  }
  .addpostimgcustom{
	padding-right:6px
  }
  
  .comment-list span.username {
    font-size: 14px;
    font-weight: bold;
}

.newdivavatar img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}
.newdivavatar:hover{
	text-decoration: none !important;
}


#headernavbarimage::after {
    margin-left: 100%;
    margin-top: 0px;
}

.dropdown-toggle::after {
	/* margin-left: 100%; */
    margin-top: 0px;
}


/* .custom-dialog {width:48% !important; top:20%;} */
.tagsclass{
	background-color: #cfc8c8;
    text-align: center;
    padding: 4px;
    border: 1px solid gray;
    margin-left: 3px;
	display: inline-block;
}