/* Css reset  */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	font-family: "Raleway", sans-serif !important;
	/* font-family: "Raleway", sans-serif; */
	/* font-family: "Raleway", sans-serif; */
	
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}
body {
	line-height: 1;
}
ol,
ul {
	list-style: none;
}
blockquote,
q {
	quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: "";
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/* reset css end  */
/* header css  */
.header {
	position: fixed;
	top: 0;
	width: 100%;
	transition: all 0.3s ease;
	z-index: 999;
	background: #fff;
	box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.07);
}
div#navbarNav {
	justify-content: flex-end;
}
.fixed-top {
	background-color: #2221b9;
}
.header .navbar {
	padding: 0.5rem 1rem 0;
	flex-wrap: wrap;
}
.login-btn-mobile {
	display: none;
}
.navbar-brand img {
	width: 75px;
}
.navbar-light .navbar-toggler {
	color: rgba(0, 0, 0, 0.5);
	border-color: rgba(0, 0, 0, 0.1);
	background: #7fb3e0;
	border-radius: 8px;
	outline: none !important;
}
/* .navbar-light .navbar-brand {
  width: 179px;
} */
.custom-nav-links {
	flex-grow: unset;
}
.custom-nav-links .navbar-nav {
	gap: 26px;
}
.custom-nav-links .nav-link {
	font-size: 18px;
	color: #08357a !important;
	font-weight: 400;
	padding-right: 0 !important;
	padding-left: 0 !important;
	padding-bottom: 15px;
	margin-bottom: -2px;
	transition: all 0.3s ease;
}
.custom-nav-links .nav-link:hover {
	color: #ffc000 !important;
}
.custom-nav-links .nav-item .active {
	color: #ffc000 !important;
}
.nav-social-links {
	display: flex;
	justify-content: flex-end;
	gap: 31px;
	padding-bottom: 10px;
	width: 100%;
	margin-top: -10px;
}
.navbar-toggler-btn {
	color: #fff;
}
.login-btn-desktop {
	display: flex;
	gap: 30px;
}
.address-details {
	display: flex;
	align-items: center;
	gap: 30px;
}
.address-details small:first-child {
	margin-right: 15px;
}
.address-details small {
	display: flex;
	align-items: center;
	gap: 15px;
}
.address-details small span {
	color: #fff;
	font-size: 18px;
}
.address-details small span img {
	width: 20px;
}
.social-links-icon a {
	margin-right: 20px;
	position: relative;
}
.social-links-icon a::after {
	content: "";
	position: absolute;
	right: -7px;
	top: -6px;
	color: #fff;
	width: 1px;
	height: 20px;
	background-color: #fff;
}
.social-links-icon a:last-child {
	margin-right: 0px;
}
.social-links-icon a:last-child::after {
	content: unset;
}
.login-btn button {
	padding: 10px 35px;
	font-size: 18px;
	color: #fff;
	background: #08357a;
	border-radius: 8px;
	border: none;
	outline: none !important;
	transition: all 0.3s ease-in-out;
}
.login-btn button:hover {
	background: #7fb3e0;
}
.join-btn button {
	padding: 10px 35px;
	font-size: 18px;
	color: #fff;
	background: #7fb3e0;
	border-radius: 8px;
	border: none;
	outline: none !important;
	transition: all 0.3s ease-in-out;
}
.join-btn button:hover {
	background: #08357a;
}

/* hero section css  */
.hero-banner {
	width: 100%;
	background-image: url(../images/hero-banner-bg.png);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	padding-top: 100px;
	padding-bottom: 39px;
}
.hero-banner > .container {
	background: rgba(255, 255, 255, 0.82);
	backdrop-filter: blur(28px);
	border-radius: 8px;
}
.hero-banner-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
}
.hero-banner h2 {
	font-weight: bold;
	font-size: 49px;
	color: #08357a;
	line-height: 1.3;
	padding-bottom: 45px;
	position: relative;
}
.hero-banner h2::after {
	content: "";
	position: absolute;
	bottom: 35px;
	height: 3px;
	width: 30%;
	background-color: #7fb3e0;
	left: 0;
	right: 0;
}
.hero-banner p {
	font-size: 24px;
	color: #4a4a4a;
	padding-bottom: 25px;
	line-height: 1.25;
	font-weight: 400;
	font-family: "Raleway", sans-serif;
}
.help-btn button {
	padding: 15px 40px;
	font-size: 18px;
	color: #fff;
	background: #08357a;
	border-radius: 8px;
	border: none;
	outline: none !important;
	transition: all 0.3s ease-in-out;
}
.help-btn button:hover {
	background: #7fb3e0;
}
.hero-banner-image img {
	width: 100%;
}

/* suitable section css starts  */

.suitable-home-section {
	padding-block: 50px;
}
.suitable-home-section h2 {
	font-weight: bold;
	font-size: 40px;
	width: 45%;
	margin-inline: auto;
	color: #08357a;
	text-align: center;
	padding-bottom: 30px;
	line-height: 1.35;
	position: relative;
	text-transform: uppercase;
}
.suitable-home-section h2 span {
	display: block;
	font-size: 18px;
	color: #7fb3e0;
	text-transform: capitalize;
}
.suitable-home-section h2::after {
	content: "";
	position: absolute;
	bottom: 25px;
	height: 3px;
	width: 25%;
	background-color: #7fb3e0;
	left: 0;
	right: 0;
	margin-inline: auto;
}
.suitable-card {
	text-align: center;
	background-color: #fff;
	border-radius: 5px;
	box-shadow: 2px 8px 34px 2px rgba(35, 39, 185, 0.08);
	padding: 10px 10px 15px 10px;
}
.suitable-card-img {
	padding-bottom: 15px;
}
.suitable-card-img img {
	width: 100%;
    border-radius: 8px;
    height: 160px;
	object-fit: cover;
}
.suitable-card h3 {
	font-weight: 600;
    font-size: 22px;
    color: #08357a;
    padding-bottom: 15px;
    min-height: 57px;
}
.suitable-card p {
	font-weight: normal;
	font-size: 16px;
	color: #4a4a4a;
	padding-bottom: 15px;
	min-height: 80px;
	font-family: "Raleway", sans-serif;
}
.suitable-card button {
	background-color: #08357a;
	border: none;
	border-radius: 8px;
	font-weight: 600;
	font-size: 16px;
	color: #fff;
	outline: none !important;
	padding: 10px 30px;
	transition: all 0.3s ease-in-out;
}
.suitable-card button:hover {
	background: #7fb3e0;
}

/* feed section css  */
.latest-feed-section {
	padding-bottom: 50px;
}
.latest-feed-section h2 {
	font-weight: bold;
	font-size: 35px;
	width: 100%;
	margin-inline: auto;
	color: #08357a;
	text-align: center;
	margin-bottom: 40px;
	line-height: 1.35;
	position: relative;
	border-bottom: 4px solid #7fb3e0;
	max-width: fit-content;
}
.feed-card {
	background: #ffffff;
	box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.11);
	border-radius: 4px;
	display: flex;
	align-items: center;
	transition: 0.5s;
}
.feed-card:hover {
	background-color: #eee;
}
.feed-card-img {
	position: relative;
	flex-basis: 50%;
	min-height: 250px;
	display: flex;
}
.feed-card-img img {
	width: 300px;
    object-position: 50%;
    object-fit: cover;
    height: 250px;
}
.feed-card-img span {
	position: absolute;
	top: 12px;
	left: 15px;
	background: #ffc000;
	border-radius: 4px;
	padding: 5px 10px;
	color: #323232;
}
.feed-card-description {
	flex-basis: 50%;
	padding-inline: 20px;
	margin-top: 10px;
    margin-bottom: 10px;
}
.feed-card-description p {
	color: #4a4a4a;
	font-weight: normal;
	font-size: 14px;
	padding-bottom: 15px;
}
.feed-card-description h4 {
	font-weight: bold;
	font-size: 18px;
	color: #08357a;
	padding-bottom: 15px;
}
.feed-card-description h4 a {
	text-decoration: none;
	color: inherit;
	transition: all 0.3s ease-in-out;
	text-transform: capitalize;
}
.feed-card-description h4 a:hover {
	color: #7fb3e0;
}
.feed-card-description h5 {
	font-weight: 300;
	font-size: 16px;
	color: #4a4a4a;
	padding-bottom: 10px;
	font-family: "Raleway", sans-serif;
}
.feed-rating .rating-stars ul > li.star.hover {
	color: #d7524d;
}

/* Selected state of the stars */

.feed-rating .rating-stars ul > li.star.selected {
	color: #d7524d;
}

.feed-rating .text-orange {
	color: #d7524d;
}

.feed-rating .fill-orange {
	fill: #d7524d;
}
.feed-card-info {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.feed-card-info div {
	display: flex;
	align-items: center;
	gap: 10px;
}
.feed-card-info div span:last-child {
	font-family: "Raleway", sans-serif;
}
.view-more-feed {
	text-align: center;
	margin-top: 30px;
}
.view-more-feed button {
	padding: 10px 30px;
	background-color: #08357a;
	color: #fff;
	border: none;
	outline: none;
	font-size: 16px;
	border-radius: 4px;
	transition: all 0.3s ease-in-out;
}

.view-more-feed button:hover {
	background: #7fb3e0;
}

/* benefits section css  */

.benefits-section {
	padding-block: 40px;
	background-color: #000;
}
.benefits-content {
	padding-block: 20px;
	width: 80%;
}
.benefits-content h2 {
	font-weight: 600;
	font-size: 36px;
	color: #fff;
	padding-bottom: 30px;
	text-transform: capitalize;
	line-height: 1.25;
	position: relative;
}
.benefits-content h2::after {
	content: "";
	position: absolute;
	bottom: 21px;
	height: 3px;
	width: 20%;
	background-color: #7fb3e0;
	left: 0;
	right: 0;
}
.benefits-content h2 span {
	display: block;
	color: #7fb3e0;
	font-size: 18px;
	padding-bottom: 5px;
}
.benefits-content p {
	font-weight: 600;
	font-size: 25px;
	color: #fff;
	padding-bottom: 20px;
}
.benefits-content h4 {
	font-weight: bold;
	font-size: 20px;
	color: #fff;
	padding-bottom: 15px;
}
.benefits-content ul {
	padding-bottom: 15px;
}
.benefits-content ul li {
	display: flex;
	font-weight: 300;
	gap: 10px;
	font-size: 20px;
	color: #fff;
	padding-bottom: 15px;
	line-height: 1.3;
	font-family: "Raleway", sans-serif;
}
.benefits-content ul li span {
	font-family: "Raleway", sans-serif;
}
.total-members {
	width: 42%;
	padding: 15px 10px;
	background: #fff;
	border-radius: 10px;
	display: flex;
	gap: 20px;
	align-items: center;
}
.total-members span {
	font-weight: bold;
	font-size: 40px;
	color: #293ebb;
}
.benefits-images {
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
}
.benefits-images div {
	flex-basis: 47%;
}
.benefits-images div img {
	width: 100%;
}
.benefits-images .benefits-images-second-child img {
	border-radius: 0px 60px 0px 0px;
}
.benefits-images .benefits-images-third-child img {
	border-radius: 0px 0px 0px 60px;
}

/* choose us section css  */

.choose-us-section {
	padding: 50px 0px;
	background-image: url(../images/chooses-us-bg.png);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-attachment: fixed;
}
.choose-us-section > .container {
	background: rgba(255, 255, 255, 0.77);
	backdrop-filter: blur(21px);
	padding-block: 50px;
}
.choose-us-section h2 {
	font-weight: bold;
	font-size: 35px;
	color: #08357a;
	padding-bottom: 30px;
	position: relative;
	text-align: center;
	text-transform: uppercase;
}
.choose-us-section h2 span {
	display: block;
	font-size: 18px;
	color: #7fb3e0;
	padding-bottom: 10px;
}
.choose-us-section h2::after {
	content: "";
	position: absolute;
	bottom: 20px;
	height: 3px;
	width: 10%;
	background-color: #7fb3e0;
	left: 0;
	right: 0;
	margin-inline: auto;
}
.choose-us-content {
	padding-block: 50px;
	text-align: center;
}
.choose-us-content h4 {
	font-weight: 500;
	font-size: 29px;
	color: #08357a;
	padding-bottom: 30px;
}
.choose-us-content h5 {
	font-weight: 300;
	font-size: 20px;
	color: #4a4a4a;
	font-family: "Raleway", sans-serif;
}
.choose-us-content h2 {
	font-weight: bold;
	font-size: 35px;
	color: #252eba;
	padding-bottom: 30px;
	position: relative;
}
.choose-us-content h2::after {
	content: "";
	position: absolute;
	bottom: 20px;
	height: 3px;
	width: 47%;
	background-color: #ffc000;
	left: 0;
	right: 0;
}
.choose-us-flex {
	display: flex;
	align-items: center;
	gap: 20px;
	margin-bottom: 20px;
	background-color: #008783;
	padding: 10px;
	border-radius: 4px;
}
.choose-us-flex-content h4 {
	font-weight: 600;
	font-size: 24px;
	color: #fff;
	padding-bottom: 15px;
}
.choose-us-flex-content p {
	font-size: 18px;
	color: #fff;
}
.choose-us-img img {
	width: 100%;
}

/* latest news section css  */
.latest-news-section {
	padding-block: 50px;
	background: #fff;
}
.latest-news-section h5 {
	text-align: center;
	position: relative;
	color: #08357a;
}
.latest-news-section h5::after {
	content: "";
	position: absolute;
	bottom: 10px;
	height: 3px;
	width: 18%;
	background-color: #7fb3e0;
	left: 0;
	right: 0;
	margin-inline: auto;
}
.latest-news-section .suitable-card {
	text-align: left;
	background-color: #fff;
}
.latest-news-section .suitable-card h3 {
	min-height: 50px;
	color: #08357a;
}
.latest-news-section .suitable-card p {
	color: #323232;
	min-height: auto;
}
.news-card-top a {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 2;
}
.news-card-top .overlay-news-image {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 18px;
	right: 0;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 1;
}
/* ======Our Special features ====== */
.special-features {
	padding-bottom: 50px;
}
.special-features .feature-title {
	position: relative;
}
.special-features .feature-title::after {
	content: "";
	position: absolute;
	bottom: 16px;
	height: 3px;
	width: 17%;
	background-color: #ffc000;
	left: 0;
	right: 0;
}
.main-features {
	background-color: #fa6755;
	padding: 30px 30px;
	width: 635px;
	margin-left: 67px;
	z-index: 9;
	position: absolute;
}
.feature-flex {
	padding-top: 70px;
}

.market-main {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.main-title {
	font-size: 25px;
	font-weight: 700;
	margin-block: 20px;
	position: relative;
	color: #fff;
}

.main-title::after {
	content: "";
	position: absolute;
	left: 0px;
	top: 30px;
	width: 45px;
	height: 3px;
	background: #ffc000;
	border-radius: 2px;
}
.main-features .about-feature {
	color: #fff;
}

.main-features button {
	background: #fff;
	border: none;
	color: #323232;
	padding: 10px 30px;
	border-radius: 8px;
	outline: none;
	margin-block: 25px;
}

/* .main-features p {
    color: #606060;
    line-height: 20px;
} */

.feature-title {
	font-size: 35px;
	font-weight: bold;
	padding-block: 30px;
}

.features {
	color: #252eba;
}

.good-sale {
	right: 0;
}

.feature-images {
	width: 100%;
	border: 5px solid #fa6755;
}

/* ======why chose us==== */
.about-feature {
	color: #606060;
	font-size: 20px;
	line-height: 1.35;
	font-family: "Raleway", sans-serif;
}

.why-chose-us {
	padding-block: 50px;
	background-color: #000;
}
.why-chose-us h5 {
	width: 100%;
	line-height: 1.35;
	color: #fff;
	position: relative;
	text-transform: uppercase;
}
.why-chose-us h5 span {
	display: block;
	color: #7fb3e0;
	font-size: 18px;
}
.why-chose-us h5::after {
	content: "";
	position: absolute;
	bottom: 20px;
	height: 3px;
	width: 20%;
	background-color: #7fb3e0;
	left: 0;
	right: 0;
}
.why-chose-us .about-feature {
	color: #fff;
	font-weight: 300;
	padding-bottom: 10px;
}
.chose-main-info {
	display: flex;
	align-items: center;
}

.our-choices {
	position: relative;
	border: 6px solid #fff;
	border-radius: 20px;
}
.our-choices img {
	width: 100%;
}
.video-icon {
	position: absolute;
	top: 50%;
	left: 50%;
	background: transparent;
	transform: translate(-50%, -50%);
	border: none;
	outline: none !important;
}
.video-icon img {
	width: 100%;
}

/* ====== tokan section CSS===== */
.scan-bar {
	position: relative;
	background: #008783;
	padding: 20px;
	border-radius: 8px;
}

.scanbar-image {
	width: 100%;
	margin-bottom: 60px;
	border-radius: 8px;
}

.buy-token {
	background-color: #fa6755;
	border-radius: 8px;
	padding: 14px 20px;
	margin-top: 10px;
}

.buy-token h6 {
	color: #ffffff;
	font-size: 14px;
	font-weight: 600;
}

.buy-token button {
	background: no-repeat;
	border: 1px solid #ffff;
	padding: 15px 25px;
	border-radius: 8px;
	color: #ffffff;
}

.buy-token p {
	color: #fff;
	font-size: 16px;
	font-weight: 600;
	padding-block: 25px;
}

.chose-main-info h6 {
	font-weight: bold;
	font-size: 24px;
	color: #fff;
	padding-block: 20px;
}

.logo-download-app {
	width: 170px;
}
section.help-find {
	padding-block: 50px;
}
.help-find-feature .feature-title {
	color: #08357a;
	position: relative;
}
.help-find-feature .feature-title span {
	display: block;
	font-size: 18px;
	padding-bottom: 10px;
	color: #7fb3e0;
}
.help-find-feature .feature-title::after {
	content: "";
	position: absolute;
	bottom: 16px;
	height: 3px;
	width: 44%;
	background-color: #7fb3e0;
	left: 0;
	right: 0;
}
.help-find-feature .about-feature {
	color: #4a4a4a;
	font-weight: 300;
}

.token-bth {
	padding: 10px 40px 15px 40px;
	font-size: 18px;
	color: #ffffff;
	background: #08357a;
	border-radius: 8px;
	border: none;
	outline: none;
	margin-block: 30px;
	transition: all 0.3s ease-in-out;
}
.token-bth:hover {
	background: #7fb3e0;
}
/* .scan-bar {
  position: relative;
  background: #2630ba;
  padding: 20px;
  border-radius: 8px;
} */
.scanbar-image {
	width: 100%;
	margin-bottom: 60px;
	border-radius: 8px;
}
.notification-option {
	position: absolute;
	bottom: 13px;
	left: 13px;
	width: 90%;
}
.serving-hours {
	background: #ffc000;
	box-shadow: 0px 4px 30px rgb(0 0 0 / 5%);
	border-radius: 8px;
	height: 150px;
	position: relative;
}
.serving-connters {
	width: 223px;
    position: absolute;
    left: 16px;
	top: 70px;
	background: #ffffff;
	box-shadow: 0px 4px 30px 6px rgb(0 0 0 / 5%);
	border-radius: 8px;
}
.display-main {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
	padding: 5px;
}

.app-download {
	display: flex;
	gap: 15px;
	flex-wrap: wrap;
}

/* ======UShx-Features======= */
.UShx-Features {
	background-color: #000;
	padding-block: 50px;
}
.features-display .feature-title {
	color: #fff;
	position: relative;
}
.features-display .feature-title::after {
	content: "";
	position: absolute;
	bottom: 20px;
	height: 3px;
	width: 38%;
	background-color: #ffc000;
	left: 0;
	right: 0;
}
.features-display .about-feature {
	color: #fff;
}

/* .round-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #bbebff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
} */

.round-icon img {
	width: 80px;
}
.feature-titles {
	text-align: center;
	padding-bottom: 40px;
}

.feature-titles h5 {
	font-weight: 400;
	font-size: 18px;
	color: #fff;
	margin-block: 20px;
}

.feature-titles p {
	color: #fff;
	font-size: 14px;
	font-weight: 400;
}

/* testimonials */
.testimonials {
	padding: 50px 0px 50px 0px;
	position: relative;
}
.main-prnt-div {
	position: relative;
	padding: 60px 0px 60px 0px;
	width: 100%;
	margin: 0 auto;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 30px;
	background: linear-gradient(100.45deg, #0c2141 -34.44%, #1f21aa 110.14%);
	border-radius: 15px;
	border-radius: 25px;
}

.quots img {
	width: 25px;
	margin-bottom: 20px;
}
.main-child-div {
	background: #fff;
	width: 35%;
	/* text-align: center; */
	padding: 50px 25px 50px 75px;
	border-radius: 0px 32px 32px 0px;
}
.main-child-div h2 {
	font-weight: bold;
	font-size: 40px;
	color: #4a4a4a;
	position: relative;
}
.main-child-div h2::after {
	content: "";
	position: absolute;
	bottom: -15px;
	height: 3px;
	width: 78%;
	background-color: #ffc000;
	left: 0;
	right: 0;
}
.mian-child-2 {
	width: 60%;
}
.client-detail {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}
.client-review {
	color: #fff;
	width: 70%;
	font-size: 16px;
	margin-bottom: 50px;
	font-weight: 400;
	display: flex;
	gap: 20px;
	line-height: 1.25;
}
.client-review span img {
	width: 30px;
}
.client-img img {
	width: 110px;
}
.client-detail-child h5 {
	font-weight: 600;
	font-size: 24px;
	color: #fff;
	margin-bottom: 10px;
}
.client-detail-child a {
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	color: #ffc000;
	display: block;
	text-decoration: none;
}
.client-detail-child a:hover {
	color: #ffc000 !important;
}
.client-detail-child {
	margin-left: 20px;
}
.slick-prev-arrow,
.slick-next-arrow {
	padding-top: 3px;
	color: #fff;
	height: 30px;
	text-align: center;
	border: 1px solid #fff;
	width: 30px;
	position: absolute;
	bottom: -30%;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	display: flex;
	align-items: center;
	justify-content: center;
}
.slick-prev-arrow {
	left: 0%;
}
.slick-prev-arrow:hover,
.slick-next-arrow:hover {
	color: #252eba;
	background: #fff;
	transform: translateY(-3px);
}
.slick-next-arrow {
	left: 6%;
}

/* call to action css  */

.call-to-action-main {
	width: 100%;
	margin-inline: auto;
	padding-block: 50px;
	align-items: center;
	background-color: #fff;
	position: relative;
	z-index: 99;
	box-shadow: -3px 7px 34px rgb(0 0 0 / 10%);
	border-radius: 24px;
	padding-inline: 40px;
	margin-bottom: 50px;
}
.call-to-action-content h2 {
	font-weight: 500;
	font-size: 30px;
	color: #08357a;
	padding-bottom: 15px;
}
.call-to-action-content p {
	font-size: 18px;
	color: #323232;
	font-weight: 300;
	font-family: "Raleway", sans-serif;
}
.call-subscribe {
	position: relative;
}
.call-subscribe input {
	padding: 15px;
	width: 100%;
	background: #f3f3f3;
	border-radius: 8px;
	border: none;
	outline: none;
	font-size: 16px;
	color: #606060;
	font-family: "Raleway", sans-serif;
}
.call-subscribe button {
	padding: 10px 30px;
	background: #08357a;
	border-radius: 8px;
	border: none;
	outline: none;
	position: absolute;
	right: 7px;
	top: 7px;
	color: #fff;
	transition: all 0.3s ease-out;
}
.call-subscribe button:hover {
	background: #7fb3e0;
}
/* footer css  */
.footer {
	padding-top: 90px;
	padding-bottom: 30px;
	background: #f5f5f5;
	border-radius: 25px 25px 0px 0px;
}
.footer-content p {
	font-size: 20px;
	color: #606060;
	padding-top: 25px;
	font-weight: 300;
	line-height: 1.25;
	font-family: "Raleway", sans-serif;
}
.footer-contact h2 {
	font-weight: 300;
	font-size: 18px;
	color: #606060;
	padding-bottom: 10px;
	font-family: "Raleway", sans-serif;
}
.footer-contact {
	padding-top: 57px;
}
.footer-contact h2 .contact-to {
	color: #08357a;
	margin-right: 3px;
	font-weight: 400;
	font-family: "Raleway", sans-serif;
}
.footer-contact h2 span:last-child {
	font-family: "Raleway", sans-serif;
}
.footer-icon a {
	margin-right: 15px;
	text-decoration: none;
}
.footer-link h2 {
	color: #08357a;
	font-size: 24px;
	margin-bottom: 71px;
}
.footer-link a {
	display: block;
	margin-bottom: 32px;
	color: #606060;
	text-decoration: none;
	font-weight: 300;
	font-family: "Raleway", sans-serif;
	transition: all 0.3s ease-in-out;
}
.footer-link a:hover {
	color: #7fb3e0;
}
.state-links {
	display: flex;
	gap: 40px;
}
.state-links div {
	flex-basis: 50%;
}
.play-store-icon {
	display: flex;
	gap: 15px;
	align-items: center;
}
.play-store-icon h5 {
	color: #08357a;
	font-size: 24px;
}
.play-store-icon img {
	width: 150px;
}
.footer-bottom {
	display: flex;
	justify-content: space-between;
	padding-top: 30px;
	border-top: 1px solid #bababa;
}
.footer-tags {
	display: flex;
	flex-wrap: wrap;
	row-gap: 17px;
	column-gap: 14px;
}
.footer-tags div {
	flex-basis: auto;
}
.footer-tags a {
	margin: 0;
	padding: 10px 10px;
	background: #ffffff;
	border: 1px solid #e7e7e7;
	box-shadow: 2px 9px 11px -3px rgba(35, 39, 185, 0.08);
	border-radius: 5px;
	font-weight: 300;
	font-size: 18px;
	color: #08357a;
}
.footer-tags a:hover {
	color: #7fb3e0;
}
/* listing css  */

/* listing banner css  */
.listing-banner {
	width: 100%;
	min-height: 60vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background-image: url(../images/listing-banner-image.png);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}
.listing-banner h2 {
	font-weight: bold;
	font-size: 60px;
	color: #fff;
}
/* listing filters css  */
.listing-filters {
	padding-top: 110px;
}
.listing-filters h2 {
	font-weight: 600;
	font-size: 40px;
	color: #08357a;
	padding-bottom: 30px;
}
.listing-main {
	padding: 15px;
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	border-radius: 8px;
	max-height: 80px;
	height: auto;
	overflow: hidden;
	transition: max-height 0.3s ease-out;
}
.listing-main.show-content {
	max-height: unset;
}
.listing-main .listing-input {
	flex-basis: 23%;
	position: relative;
}
.listing-main .listing-input span {
	position: absolute;
	top: 0;
	left: 7px;
	height: 100%;
	display: flex;
	align-items: center;
}
.listing-main .listing-input span img {
	width: 20px;
}
.listing-main .listing-input select {
	width: 100%;
	padding: 15px 15px 15px 35px;
	font-weight: normal;
	font-size: 16px;
	color: rgba(0, 0, 0, 0.9);
	background: #f8f8f8;
	border: 1px solid #d2d2d2;
	border-radius: 10px;
	outline: none;
	border-radius: 10px;
	background-image: url("../images/select-dropdown-icon.svg");
	background-repeat: no-repeat;
	background-position: right 1rem center;
	background-size: 1em;
	-webkit-appearance: none;
	appearance: none;
	cursor: pointer;
	font-family: "Raleway", sans-serif;
}
.view-more-content > span {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	cursor: pointer;
	max-width: fit-content;
	margin-inline: auto;
	border-bottom: 1px solid #323232;
	font-size: 16px;
	color: #323232;
	font-weight: 500;
	font-family: "Raleway", sans-serif;
}

/* filter results css  */
.filter-results {
	padding-top: 80px;
	padding-bottom: 50px;
}
.filter-results h2 {
	font-weight: bold;
	font-size: 35px;
	color: #08357a;
	line-height: 1.35;
	position: relative;
	padding-bottom: 15px;
}
.filter-results h2::after {
	content: "";
	position: absolute;
	bottom: 10px;
	height: 4px;
	width: 12%;
	background-color: #7fb3e0;
	left: 0;
	right: 0;
}
.filter-results .filter-description {
	font-weight: 300;
	font-size: 18px;
	color: #323232;
	padding-bottom: 30px;
	font-family: "Raleway", sans-serif;
}
.filter-results .white-icon {
	display: none;
}
/* .filter-results .feed-card:hover {
  background: #008783;
} */
/* .filter-results .feed-card:hover .feed-card-description p,
.filter-results .feed-card:hover .feed-card-description h4,
.filter-results .feed-card:hover .view-more-details a,
.filter-results .feed-card:hover .feed-card-info div span {
  color: #fff;
} */
/* .filter-results .feed-card:hover .white-icon {
  display: inline-block;
}
.filter-results .feed-card:hover .dark-icon {
  display: none;
} */
.result-card {
	position: relative;
	background: #ebf9ff;
	margin-bottom: 30px;
}
.result-card > img {
	width: 100%;
}
.result-card-content {
	padding: 20px 10px;
}
.result-card-content h6 {
	font-weight: normal;
	font-size: 14px;
	color: #4a4a4a;
	padding-bottom: 8px;
}
.result-card-content h4 {
	font-weight: bold;
	font-size: 18px;
	color: #4a4a4a;
	padding-bottom: 8px;
	cursor: pointer;
}
.rating-widget {
	margin-bottom: 15px;
}

.rating-stars ul {
	list-style-type: none;
	padding: 0;
	-moz-user-select: none;
	-webkit-user-select: none;
}

.rating-stars ul > li.star {
	display: inline-block;
}
.view-more-details a {
	color: #4a4a4a;
	font-size: 16px;
	font-weight: 600;
	text-decoration: underline;
	display: inline-flex;
	align-items: center;
}

/* Idle State of the stars */

.rating-stars ul > li.star {
	font-size: 1em;
	/* Change the size of the stars */
	color: #ccc;
	/* Color on idle state */
}

/* Hover state of the stars */

.rating-stars ul > li.star.hover {
	color: #f3b95a;
}

/* Selected state of the stars */

.rating-stars ul > li.star.selected {
	color: #f3b95a;
}

.text-orange {
	color: #f3b95a;
}

.fill-orange {
	fill: #f3b95a;
}

/* five star  */

.rating-sub-heading {
	font-size: 60px;
	font-weight: 600;
	font-family: "Raleway", sans-serif;
	margin-bottom: 30px;
	text-align: center;
	color: #185641;
}

.rate {
	display: flex;
	justify-content: center;
	margin-bottom: 30px;
	flex-direction: row-reverse;
}

.rate:not(:checked) > input {
	position: absolute;
	top: -9999px;
}

.rate:not(:checked) > label {
	margin: 0px 10px;
	float: right;
	width: 3em;
	overflow: hidden;
	white-space: nowrap;
	cursor: pointer;
	font: 18px;
	color: #ccc;
}

.rate:not(:checked) > label:before {
	content: "\f005";
	font-family: fontawesome;
	font-size: 51px;
}

.rate > input:checked ~ label {
	color: #ffc700;
}

.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
	color: #deb217;
}

.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
	color: #c59b08;
}

/* five starc// */

.result-card-icons {
	display: flex;
	justify-content: space-between;
}
.result-card-icons div {
	display: flex;
	align-items: center;
	gap: 7px;
}
.result-card-icons div span {
	font-size: 12px;
	color: #000;
}
.result-card-tag {
	position: absolute;
	top: 10px;
	left: 5px;
	padding: 5px 10px;
	background: #252eba;
	font-size: 16px;
	color: #fff;
	border-radius: 4px;
}
.result-pagination .page-item {
	margin-inline: 5px;
}
.result-pagination .page-item .page-link {
	color: #000000;
	background: #ebf9ff;
}
.result-pagination .page-item .page-link:hover {
	background: #252eba;
	color: #fff;
}
.contact-us {
	padding-block: 50px;
}
.contact-us h2 {
	font-weight: bold;
	font-size: 35px;
	color: #252eba;
	padding-bottom: 30px;
	position: relative;
}
.contact-us h2::after {
	content: "";
	position: absolute;
	bottom: 15px;
	height: 4px;
	width: 22%;
	background-color: #ffc000;
	left: 0;
	right: 0;
}
.contact-us h2 span {
	display: block;
}
.contact-us-image img {
	width: 100%;
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1);
}
.contact-us-form p {
	font-size: 18px;
	color: #323232;
	padding-bottom: 20px;
}
.contact-us-input {
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
}
.contact-us-input div {
	flex-basis: 48%;
}
.contact-us-input div input,
.contact-us-input div select {
	width: 100%;
	background: #e6f2f7;
	border-radius: 10px;
	font-size: 16px;
	color: #000;
	padding: 10px 15px;
	border: none;
	outline: none;
}
.textarea-input {
	margin-top: 20px;
}
.textarea-input textarea {
	width: 100%;
	background: #e6f2f7;
	border-radius: 10px;
	font-size: 16px;
	color: #000;
	padding: 10px 15px;
	border: none;
	outline: none;
}
.submit-btn {
	text-align: right;
	margin-top: 20px;
}
.submit-btn button {
	border: none;
	outline: none;
	background: #1e12b8;
	border-radius: 8px;
	color: #fff;
	font-size: 16px;
	color: #ffffff;
	padding: 10px 20px;
}
.order-contact {
	display: flex;
	align-items: center;
}
/* video modal css  */
.video-modal-main .modal-content {
	background: transparent;
}
.video-modal {
	background: transparent;
	position: relative;
	padding: 0;
}
.video-modal .close {
	color: #000;
	opacity: 1;
	position: absolute;
	right: 15px;
	top: 0px;
	font-size: 46px;
	outline: none !important;
	z-index: 99999;
}
.video-modal .close:hover {
	color: #000;
}
/* =========Detail Page===== */
/* ==hero banner=== */
.detail-images {
	width: 100%;
	border: 2px solid #fff;
}

.house-detail-images {
	margin-top: 35px;
}

/* ===Grand Family House === */
.family-house {
	width: 70%;
	margin: auto;
	margin-block: 40px;
}

.family-house h2 {
	color: #252eba;
	font-size: 35px;
	font-weight: bold;
	margin-bottom: 15px;
}

.check-in {
	color: #27ad77;
	margin-right: 10px;
}

.location-check {
	font-size: 18px;
	color: #6d6d6d;
	margin-top: 10px;
	/* padding-block: 30px; */
	line-height: 1.5;
	font-family: "Raleway", sans-serif;
}

.family-house h5 {
	font-size: 25px;
	font-weight: bold;
	color: #252eba;
	margin-block: 40px;
}

.family-house p {
	font-weight: normal;
	font-size: 16px;
	color: #6d6d6d;
	line-height: 20px;
}

.property-detailed {
	width: 23%;
	text-align: center;
	padding-block: 25px;
	background: #ffffff;
	border: 3px solid #dfdfdf;
	box-sizing: border-box;
	box-shadow: 2px 8px 18px 2px rgb(35 39 185 / 8%);
	border-radius: 8px;
}

.property-detailed h6 {
	color: #08357a;
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 20px;
}

.property-detailed p {
	font-size: 18px;
	font-weight: 600;
	color: #6d6d6d;
	font-family: "Raleway", sans-serif;
}

.overview-property {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	margin-block: 50px;
}

/* ====Slider===== */
.result-card {
	position: relative;
	background: #ebf9ff;
	margin-bottom: 30px;
}

.result-card > img {
	width: 100%;
}

.result-card-content {
	padding: 20px 10px;
}

.result-card-content h6 {
	font-weight: normal;
	font-size: 14px;
	color: #4a4a4a;
	padding-bottom: 8px;
}

.result-card-content h4 {
	font-weight: bold;
	font-size: 18px;
	color: #4a4a4a;
	padding-bottom: 8px;
}

.rating-widget {
	margin-bottom: 15px;
}

.rating-stars ul {
	list-style-type: none;
	padding: 0;
	-moz-user-select: none;
	-webkit-user-select: none;
}

.rating-stars ul > li.star {
	display: inline-block;
}

/* Idle State of the stars */
.rating-stars ul > li.star {
	font-size: 1em;
	/* Change the size of the stars */
	color: #ccc;
	/* Color on idle state */
}

/* Hover state of the stars */
.rating-stars ul > li.star.hover {
	color: #f3b95a;
}

/* Selected state of the stars */
.rating-stars ul > li.star.selected {
	color: #f3b95a;
}

.text-orange {
	color: #f3b95a;
}

.fill-orange {
	fill: #f3b95a;
}

/* five star  */
.rating-sub-heading {
	font-size: 60px;
	font-weight: 600;
	font-family: "Raleway", sans-serif;
	margin-bottom: 30px;
	text-align: center;
	color: #185641;
}

.rate {
	display: flex;
	justify-content: center;
	margin-bottom: 30px;
	flex-direction: row-reverse;
}

.rate:not(:checked) > input {
	position: absolute;
	top: -9999px;
}

.rate:not(:checked) > label {
	margin: 0px 10px;
	float: right;
	width: 3em;
	overflow: hidden;
	white-space: nowrap;
	cursor: pointer;
	font: 18px;
	color: #ccc;
}

.rate:not(:checked) > label:before {
	content: "\f005";
	font-family: fontawesome;
	font-size: 51px;
}

.rate > input:checked ~ label {
	color: #ffc700;
}

.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
	color: #deb217;
}

.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
	color: #c59b08;
}

/* five starc// */
.result-card-icons {
	display: flex;
	justify-content: space-between;
}

.result-card-icons div {
	display: flex;
	align-items: center;
	gap: 7px;
}

.result-card-icons div span {
	font-size: 12px;
	color: #000;
}

.result-card-tag {
	position: absolute;
	top: 10px;
	left: 5px;
	padding: 5px 10px;
	background: #252eba;
	font-size: 16px;
	color: #fff;
	border-radius: 4px;
}

.Similar-Homes {
	width: 87%;
	margin-left: auto;
	padding-block: 50px;
}

.main {
	position: relative;
	padding-block: 50px;
}

.left-arrow,
.right-arrow {
	padding-top: 3px;
	color: #fff;
	height: 30px;
	text-align: center;
	border: 1px solid #252eba;
	width: 30px;
	position: absolute;
	right: 17%;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	display: flex;
	align-items: center;
	justify-content: center;
	top: -14%;
	background: #252eba;
}

.left-arrow:hover {
	background-color: #ebf9ff;
	color: #252eba;
	border: none;
}

.right-arrow:hover {
	background-color: #ebf9ff;
	color: #252eba;
	border: none;
}

.left-arrow {
	right: 21%;
}

.main .slick-initialized .slick-slide {
	margin-left: 20px;
}
.main .result-card:hover {
	background: #008783;
}
.main .result-card:hover .result-card-content h6,
.main .result-card:hover .result-card-content h4,
.main .result-card:hover .result-card-icons div span {
	color: #fff;
}
.main .result-card-icons .white-icon {
	display: none;
}
.main .result-card:hover .white-icon {
	display: inline-block;
}
.main .result-card:hover .dark-icon {
	display: none;
}
.main .result-card {
	background: #ffffff;
	box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.11);
	border-radius: 4px;
}
.main .result-card .result-card-tag {
	background-color: #ffc000;
	color: #323232;
}

.Similar-Homes > h5 {
	font-size: 25px;
	font-weight: bold;
	color: #252eba;
	margin-left: 20px;
}

.Similar-Homes > h6 {
	font-size: 18px;
	color: #323232;
	margin-block: 10px;
	margin-left: 20px;
	margin-top: 20px;
}

/* ======================== */
/* ===Map==== */
.responsive-map {
	overflow: hidden;
	padding-bottom: 56.25%;
	position: relative;
	height: 0;
}

.responsive-map iframe {
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	position: absolute;
}

/* changes  */
.main-property {
	background: #d7524d2e !important;
}

.family-categories {
	position: relative;
}

.family-categories::before {
	content: "";
	position: absolute;
	bottom: 20px;
	height: 3px;
	width: 22%;
	background-color: #ffc000;
	left: 0;
	right: 0;
	top: 40px;
}

.description-item {
	position: relative;
}

.description-item::before {
	content: "";
	position: absolute;
	bottom: 20px;
	height: 3px;
	width: 10%;
	background-color: #ffc000;
	left: 0;
	right: 0;
	top: 32px;
}

.property-items {
	position: relative;
}

.property-items::before {
	content: "";
	position: absolute;
	bottom: 20px;
	height: 3px;
	width: 17%;
	background-color: #ffc000;
	left: 0;
	right: 0;
	top: 32px;
}

.amenities-item {
	position: relative;
}

.amenities-item::before {
	content: "";
	position: absolute;
	bottom: 20px;
	height: 3px;
	width: 9%;
	background-color: #ffc000;
	left: 0;
	right: 0;
	top: 32px;
}

.main-apartments {
	position: relative;
}

.main-apartments::before {
	content: "";
	position: absolute;
	bottom: 20px;
	height: 3px;
	width: 8%;
	background-color: #ffc000;
	left: 0;
	right: 0;
	top: 32px;
}

.main-title-head {
	color: #08357a;
	font-size: 30px;
	font-weight: bold;
	text-transform: uppercase;
	position: relative;
	margin-bottom: 35px;
	font-family: "Raleway", sans-serif;
	margin-top: 40px;
}

.main-title-head::after {
	content: "";
	position: absolute;
	width: 75px;
	height: 3px;
	background-color: #7fb3e0;
	top: 45px;
	left: 0;
	border-radius: 10px;
}

.gallery-section {
	position: relative;
	height: 75vh;
	width: 86%;
	margin-inline: auto;
	margin-right: 0;
	overflow: hidden;
}
.gallery-section .collectionSliderArrowPrev,
.gallery-section .collectionSliderArrow {
	position: absolute;
	top: -9%;
	font-size: 25px;
	width: 40px;
	height: 40px;
	background: #ebf9ff;
	border-radius: 4px;
	text-align: center;
	cursor: pointer;
	display: flex !important;
	align-items: center;
	justify-content: center;
	transition: all 0.3s ease-in-out;
}
.gallery-section .collectionSliderArrowPrev:hover,
.gallery-section .collectionSliderArrow:hover {
	background: #08357a;
	color: #fff;
}
.gallery-section .collectionSliderArrowPrev {
	right: 20%;
}
.gallery-section .collectionSliderArrow {
	right: 24%;
}

.gallery-slider {
	width: 96% !important;
}

.sale-agent-sale {
	background-color: #f5f5f575;
	padding-block: 50px;
	margin-top: 50px;
}

.about-agent h6 {
	color: #08357a;
	font-size: 17px;
	font-weight: bold;
	text-transform: uppercase;
	font-family: "Raleway", sans-serif;
	line-height: 35px;
}

.about-agent p {
	font-size: 17px;
	color: #323232;
	line-height: 35px;
	font-family: "Raleway", sans-serif;
}

.agent-info {
	display: flex;
	gap: 30px;
	margin-top: 20px;
	margin-bottom: 30px;
}

.main-info {
	color: #08357a;
	font-size: 22px;
	font-weight: bold;
	text-transform: uppercase;
	font-family: "Raleway", sans-serif;
	padding-bottom: 30px;
}

.list-styles {
	list-style-image: url(../images/round-list-style.svg);
	margin-left: 20px;
}

.social-link {
	color: #323232;
	font-size: 17px;
	line-height: 35px;
	font-family: "Raleway", sans-serif;
	padding-left: 15px;
}
.social-link a {
	font-family: "Raleway", sans-serif;
}

.main-border {
	border-right: 1px solid #dbdbdb;
}

.profile-info {
	white-space: nowrap !important;
	color: #323232;
}

.input-profile {
	background: #f3f3f3;
	border: 1px solid #c7c7c7;
	box-sizing: border-box;
	border-radius: 8px;
	padding-block: 22px;
	margin-bottom: 5px;
}

.sale-agent-sale button {
	/* text-align: center; */
	background: #08357a;
	border-radius: 8px;
	color: #fff;
	padding-block: 12px;
	padding-inline: 30px;
	text-transform: uppercase;
	font-weight: 700;
	outline: none;
	display: flex;
	justify-content: center;
	width: 225px;
	margin: auto;
	border: none;
}

.show-schedule {
	margin-left: 55px;
}

.gallery-slider-image {
	width: 97%;
	height: 400px;
}

.agent-image {
	height: 140px;
	width: 140px;
	object-fit: contain;
}

.agent-image img {
	width: 100%;
}
.apartment-section p {
	font-weight: 400;
	font-size: 18px;
	color: #323232;
	margin-bottom: 20px;
	font-family: "Raleway", sans-serif;
}

.apartment-slider {
	width: 65%;
	margin-inline: auto;
	margin-right: 0;
	/* overflow: hidden; */
	width: 86%;
}
.apartment-slider .collectionSliderArrowPrev,
.apartment-slider .collectionSliderArrow {
	position: absolute;
	top: -20%;
	font-size: 25px;
	width: 40px;
	height: 40px;
	background: #ebf9ff;
	border-radius: 4px;
	text-align: center;
	cursor: pointer;
	display: flex !important;
	align-items: center;
	justify-content: center;
	transition: all 0.3s ease-in-out;
}
.apartment-slider .collectionSliderArrowPrev:hover,
.apartment-slider .collectionSliderArrow:hover {
	background: #08357a;
	color: #fff;
}
.apartment-slider .collectionSliderArrowPrev {
	right: 20%;
}
.apartment-slider .collectionSliderArrow {
	right: 24%;
}

.apartment-card {
	background: #ffffff;
	box-shadow: 0px 4px 18px rgb(0 0 0 / 11%);
	border-radius: 4px;
	display: flex;
	align-items: center;
	flex-direction: column;
	margin-left: 20px;
}
.apartment-card .feed-card-description {
	padding: 20px;
	width: 100%;
}

.price-info {
}

.name-clint {
	text-transform: uppercase;
	color: #08357a !important;
}
.hero-banner-detail {
	width: 100%;
	background-image: url(../images/detail-banner-bg.png);
	padding-top: 100px;
	padding-bottom: 50px;
	background-repeat: no-repeat;
	background-size: cover;
	margin-bottom: 40px;
}
.map-main-icon {
	color: #7fb3e0;
	margin-right: 10px;
}

/* Login Page */
.login-section {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	min-height: 100vh;
}

.login-section-right {
	height: 100%;
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
.login-form {
	width: 400px;
	max-width: 90%;
}

.login-form-page {
	width: 100%;
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.login-form-container {
	flex: 1;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.login-form {
	width: 400px;
	max-width: 90%;
}

.login-form-title {
	font-weight: bold;
	font-size: 40px;
	width: 45%;
	color: #08357a;
	padding-bottom: 30px;
	line-height: 1.35;
	position: relative;
	text-transform: uppercase;
}

.login-form-title::after {
	content: "";
	position: absolute;
	bottom: 25px;
	height: 3px;
	width: 25%;
	background-color: #7fb3e0;
	left: 0;
	right: 0;
}

.login-message {
	color: #4a4a4a;
	margin-bottom: 20px;
	font-size: 1.2rem;
}

.login-input {
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
}

.login-input label {
	font-size: 0.9rem;
	margin-bottom: 10px;
	font-family: "Raleway", sans-serif;
	font-weight: 600;
}

.login-input input {
	width: 100%;
	height: 40px;
	background-color: #e8f0fe;
	border: none;
	outline: none;
	border-radius: 6px;
	padding: 5px;
}

.forgot-password {
	width: 100%;
	text-align: right;
	font-family: "Raleway", sans-serif;
	font-weight: 600;
}

.login-page-btn {
	width: 100%;
	height: 40px;
	color: #fff;
	background-color: #08357a;
	border-radius: 8px;
	border: none;
	outline: none;
	cursor: pointer;
	margin-top: 20px;
}

.social-media-title {
	font-family: "Raleway", sans-serif;
	width: 100%;
	text-align: center;
	margin: 40px 0;
	font-weight: 600;
}

.social-icons {
	width: 100%;
	list-style: none;
	display: flex;
	align-items: center;
	justify-content: center;
}

.social-icons li {
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #08357a;
	color: #fff;
	border-radius: 50%;
	margin: 0 5px;
	cursor: pointer;
}

/* Login Page */

/* Signup Page */
.signup-section-container {
	min-height: 100vh;
	display: flex;
}
.signup-section {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 100%;
	flex: 1;
}

.signup-logo {
	padding: 20px 0;
}

.signup-form {
	/* width: 700px;
	max-width: 90%; */
}

.signup-form-title {
	font-weight: bold;
	font-size: 40px;
	width: 45%;
	color: #08357a;
	padding-bottom: 30px;
	line-height: 1.35;
	position: relative;
	text-transform: uppercase;
	padding-left: 0;
}

.signup-form-title::after {
	content: "";
	position: absolute;
	bottom: 25px;
	height: 3px;
	width: 25%;
	background-color: #7fb3e0;
	left: 0;
	right: 0;
}

.signup-message {
	color: #4a4a4a;
	margin-bottom: 20px;
	font-size: 1.2rem;
}

.signup-input {
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
}

.signup-input label {
	font-size: 0.9rem;
	margin-bottom: 10px;
	font-family: "Raleway", sans-serif;
	font-weight: 600;
	text-align: left;
}

.signup-input input {
	width: 100%;
	height: 40px;
	background-color: #e8f0fe;
	border: none;
	outline: none;
	border-radius: 6px;
	padding: 5px;
}

.signup-input textarea {
	width: 100%;
	height: 100px;
	background-color: #e8f0fe;
	border: none;
	outline: none;
	border-radius: 6px;
	resize: vertical;
	padding: 10px;
}

.details-btns {
	padding: 0 20px;
	margin-bottom: 20px;
}

.details-btn {
	padding: 10px;
	height: fit-content;
}

.details-btn h4 {
	width: 100%;
	text-align: center;
	margin-bottom: 5px;
}

.yes-no-container {
	display: flex;
	align-items: center;
}

.yes-no-container span {
	margin-left: 10px;
}

.agree-label {
	color: gray;
}

.yes-no {
	font-size: 1rem;
}

.yes-no span {
	color: #08357a;
	cursor: pointer;
}

.hidden {
	display: none;
}

.shown {
	display: block;
}

.details-btn button {
	width: 100%;
	height: 100px;
	border: none;
	outline: none;
	border-radius: 5px;
	box-shadow: 0 0 5px gray;
	background-color: #fff;
	transition: 0.5s;
}

.details-btn button.active {
	background-color: #08357a;
}

.details-btn button.active h3 {
	color: #fff;
}

.details-btn h3 {
	color: #08357a;
	margin-bottom: 10px;
	font-family: "Raleway", sans-serif;
}

.details-btn h4 {
	color: gray;
}

.mt {
	margin-top: 97px !important;
}

.cbox input {
	display: none;
}

/* (B) ICON TO SHOW WHEN CHECKED */
.cbox input:checked ~ label::before {
	content: "✔";
}

/* (C) CUSTOM CHECKBOX SQUARE */
.cbox label::before {
	display: inline-block;
	content: "\00a0"; /* Blank space */
	width: 25px;
	height: 25px;
	margin-right: 10px;
	text-align: center;
	background: #e5eefb;
	border-radius: 5px;
}

/* (D) MORE COSMETICS */
.cbox {
	font-size: 18px;
	line-height: 20px;
	margin: 10px 0;
}
.cbox label {
	cursor: pointer;
	font-family: "Raleway", sans-serif;
	font-size: 0.9rem;
}
.cbox label:hover::before {
	background: #ddd;
}

/* (E) DISABLED CHECKBOXS & RADIO BUTTONS */
.cbox input:disabled ~ label {
	color: #aaa;
}
.cbox input:disabled ~ label::before {
	background: #ccc;
}

.forgot-password {
	width: 100%;
	text-align: right;
	font-family: "Raleway", sans-serif;
	font-weight: 600;
}

.signup-submit {
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
}

.signup-btn {
	width: 245px;
	max-width: 100%;
	height: 40px;
	color: #fff;
	background-color: #08357a;
	border-radius: 8px;
	border: none;
	outline: none;
	cursor: pointer;
	margin-top: 20px;
}

.signup-title {
	font-family: "Raleway", sans-serif;
	width: 100%;
	text-align: right;
	margin: 0;
	font-weight: 600;
}

.social-container {
	text-align: right;
}

.signup-icons {
	width: 100%;
	list-style: none;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 10px;
}

.signup-icons li {
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #08357a;
	color: #fff;
	border-radius: 50%;
	margin: 0 5px;
}

.thumbs {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 10px;
}

.thumbs img {
	width: 50px;
	height: 50px;
	border-radius: 5px;
	object-fit: cover;
}

.thumbs img:not(:first-child) {
	margin-left: 10px;
}

.type-select {
	background-color: #f8fbff;
	height: 40px;
	border: none;
	outline: none;
	border-radius: 5px;
	font-family: "Raleway", sans-serif;
	padding: 0 5px;
	font-size: 0.9rem;
	color: gray;
}

@media (max-width: 768px) {
	.social-container {
		text-align: center;
	}
	.signup-icons {
		justify-content: center;
	}
	.signup-form-title {
		text-align: center;
		width: 100%;
	}
	.signup-form-title::after {
		margin: auto;
	}
	.signup-title {
		text-align: center;
	}
}

/* Signup Page */

/* Admin */
.admin-header {
	width: 100%;
	background-color: #fff;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 20px;
}

.header-input {
	width: 400px;
	display: flex;
	align-items: center;
	background-color: #f8fbff;
	height: 45px;
	border-radius: 8px;
	padding: 3px 12px;
	

}

.header-input input {
	height: 100%;
	flex: 1;
	background: none;
	outline: none;
	border: none;
	font-family: "Raleway", sans-serif;
}

.header-input .searchIcon {
	color: gray;
	margin: 0 15px;
	font-size: 0.9rem;
}

.avatar {
	display: flex;
	align-items: center;
	justify-content: center;
}

.avatar img {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	object-fit: cover;
}

.avatar span {
	font-family: "Raleway", sans-serif;
	margin: 0 5px;
	font-size: 0.8rem;
	font-weight: 600;
}

.edit-profile-content {
	width: 100%;
	display: flex;
	/* height: calc(100vh - 91px); */
	/* overflow-y: scroll; */
}

.edit-profile-content::-webkit-scrollbar {
	display: none;
}

.sidebar {
	width: 20%;
	height: 100%;
	list-style: none;
	background-color: #f6f9fe;
	overflow-y: scroll;
	padding: 0;
}

.sidebar::-webkit-scrollbar {
	display: none;
}

/* .sidebar li {
  font-family: "Raleway", sans-serif;
  width: 100%;
  padding: 15px 20px 15px 30px;
  font-size: .9rem;
  cursor: pointer;
  transition: .5s;
} */

.sidebar-link {
	font-family: "Raleway", sans-serif;
	color: #000;
	transition: 0.5s;
}

.sidebar-link:hover {
	text-decoration: none;
}

.sidebar li:hover {
	background-color: #08357a;
	color: #fff;
}

.sidebar li:hover .sidebar-link {
	color: #fff;
}

.sidebar li.active {
	background-color: #08357a;
	color: #fff;
}

.sidebar li.active * {
	color: #fff;
}

.sidebar li i {
	margin-right: 10px;
}

.sidebar-list li a {
	width: 100%;
	padding: 15px 25px;
}
.sidebar-list li a.active {
	background: #08357a;
}
.sidebar-list li a.active {
	color: #ffffff;
}
.sidebar-list li a.active .dark-icon {
	display: none;
}
.sidebar-list li a.active .light-icon {
	display: inline-block;
}
.sidebar-list li span:first-child {
	margin-right: 15px;
}
.sidebar-list li:hover {
	background: #08357a;
}
.sidebar-list li:hover a {
	color: #ffffff;
}
.sidebar-list li:hover .dark-icon {
	display: none;
}
.sidebar-list li:hover .light-icon {
	display: inline-block;
}
.sidebar-list li a {
	font-weight: 500;
	font-size: 16px;
	color: #464646;
	text-decoration: none;
	display: flex;
	align-items: center;
	font-family: "Raleway", sans-serif;
}

.edit-container {
	flex: 1;
	/* height: calc(100vh - 91px); */
	padding: 20px;
	/* overflow-y: scroll; */
}

.edit-container::-webkit-scrollbar {
	display: none;
}

.edit-container * {
	font-family: "Raleway", sans-serif;
	font-weight: 600;
}

.edit-form {
	margin-top: 20px;
}


.edit-btn {
	width: 100px;
	max-width: 100%;
	height: 35px;
	color: #fff;
	background-color: #08357a;
	border-radius: 8px;
	border: none;
	outline: none !important;
	cursor: pointer;
	margin-top: 20px;
}

.submit-btn {
	max-width: 100%;
	height: 35px;
	color: #fff;
	background-color: #08357a;
	border-radius: 8px;
	border: none;
	outline: none !important;
	cursor: pointer;
	margin-top: 20px;
	font-size: 0.8rem;
	padding: 0 10px;
	border: 2px solid #08357a;
	transition: 0.5s;
}

.hover-btn {
	color: #fff;
	background-color: #08357a;
	border-radius: 8px;
	cursor: pointer;
	border: 2px solid #08357a;
	transition: 0.5s;
}

.hover-btn:hover {
	background-color: #fff;
	color: #08357a;
}

.file-field {
	height: 100px;
	margin-bottom: 0px;
	padding: 0 15px;
}

.file-field input {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	cursor: pointer;
}

.file-field h3 {
	color: gray;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	background-color: #f8fbff;
	border-radius: 5px;
}

.success-msg {
	background-color: #e2ffea;
	color: gray;
	padding: 10px;
	border-radius: 5px;
	font-weight: 400;
	font-size: 0.9rem;
}

.board-title {
	margin-bottom: 20px;
}

.board-msg {
	background-color: #f8fbff;
	margin-top: 10px;
	color: gray;
	position: relative;
	padding: 10px;
}

.board-msg p {
	font-weight: 400;
	margin-top: 10px;
}

.board-msg span {
	position: absolute;
	top: 10px;
	right: 10px;
	font-size: 0.8rem;
	font-size: 400;
	color: lightgray;
}

.board-form {
	width: 100%;
	height: 40px;
	margin-top: 10px;
	background-color: #f6f9fe;
	border: 1px solid lightgray;
	display: flex;
}

.board-form input {
	flex: 1;
	height: 100%;
	background: none;
	outline: none;
	border: none;
	padding: 0 10px;
}

.board-form button {
	width: 40px;
	height: 40px;
	border: none;
	outline: none;
	display: flex;
	align-items: center;
	justify-content: center;
	background: none;
	cursor: pointer;
}

.sendIcon {
	color: gray;
}

.listing-title {
	margin-bottom: 20px;
}

.listing-item {
	width: 100%;
	min-height: 100px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #f8fbff;
	border-radius: 5px;
	overflow: hidden;
	padding: 0;
	margin-left: 5px;
	flex-wrap: wrap;
	margin-bottom: 20px;
}

.listing-left {
	display: flex;
	padding: 0;
	width: 50%;
}

.listing-item img {
	height: 100%;
}

.listing-item-middle {
	min-height: 100px;
	flex: 1;
	color: gray;
	padding: 10px;
	font-size: 0.9rem;
}

.listing-item-middle h3 {
	color: #08357a;
	font-family: "Raleway", sans-serif;
	font-size: 1rem;
	margin-bottom: 5px;
	text-transform: uppercase;
}

.listing-item-middle h4 {
	margin-bottom: 10px;
}

.listing-item-middle span {
	font-size: 0.8rem;
}

.listing-item-right {
	display: flex;
	align-items: flex-end;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100px;
	width: 50%;
	padding: 10px;
}

.listing-item-right ul {
	display: flex;
	align-items: center;
}

.listing-item-right span {
	color: gray;
	font-size: 0.8rem;
}

.gold-btn {
	border: none;
	outline: none;
	background-color: #bb9125;
	font-size: 0.9rem;
	padding: 5px 10px;
	color: #fff;
	cursor: pointer;
}

.blue-btn {
	border: none;
	outline: none;
	background-color: #08357a;
	font-size: 0.8rem;
	padding: 5px 10px;
	color: #fff;
	cursor: pointer;
	border-radius: 5px;
	font-weight: 400;
}

.listing-item-right ul li:not(:last-child) {
	margin-right: 10px;
}

.activity-msg {
	background-color: #f8fbff;
	margin-top: 10px;
	padding: 10px;
}

.activity-msg p {
	font-weight: 400;
	margin-bottom: 5px;
}

.activity-msg h5 {
	width: 100%;
	text-align: right;
	color: gray;
	font-size: 0.8rem;
}

.auth-link {
	color: #fff;
}

.auth-link:hover {
	color: #fff;
	text-decoration: none;
}

.messages-list {
	height: 100%;
	list-style: none;
	overflow-y: scroll;
	padding: 0;
	/* padding: 10px; */
	background-color: #fafafa;
}

.messages-list::-webkit-scrollbar {
	display: none;
}

.messages-list form {
	width: 100%;
	display: flex;
	padding: 0 10px;
	height: 50px;
	border-bottom: 1px solid lightgray;
}

.messages-list input {
	height: 100%;
	flex: 1;
	border: none;
	outline: none;
	background: none;
	padding: 0 10px;
	font-family: "Raleway", sans-serif;
}

.messages-list form span {
	width: 40px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.messages-list form button {
	width: 40px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	outline: none;
	background: none;
}

.search-icon {
	color: #2f80ed;
}

.users-list {
	width: 100%;
}

.users-list li {
	width: 100%;
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	transition: 0.5s;
}

.users-list li:hover {
	background-color: #fff;
}

.user-left {
	height: 100%;
	display: flex;
	align-items: center;
}

.user-img {
	height: 50px;
	width: 50px;
	position: relative;
}

.user-left img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.user-online {
	position: absolute;
	bottom: 0;
	right: 0;
	width: 12px;
	height: 12px;
	border: 2px solid #fff;
	border-radius: 50%;
	background-color: #4ce417;
}

.user-left h4 {
	font-family: "Raleway", sans-serif;
	margin-left: 10px;
	font-size: 0.9rem;
	font-weight: 500;
}

.you {
	font-family: "Raleway", sans-serif;
	font-size: 0.8rem;
	color: gray;
	font-weight: 500;
	margin-right: 10px;
}

.user-right {
	background-color: #2f80ed;
	padding: 5px;
	color: #fff;
	font-family: "Raleway", sans-serif;
	font-size: 0.8rem;
}

.chat {
	height: calc(100vh - 91px);
	flex: 1;
	padding: 10px;
}

.chat * {
	font-family: "Raleway", sans-serif;
}

.chat-top {
	width: 100%;
	height: 70px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid lightgray;
}

.chat-top-left {
	height: 100%;
	display: flex;
	align-items: center;
}

.chat-top-left h3 {
	color: #1b1a57;
	font-weight: 500;
}

.chat-top-left span {
	color: gray;
	font-size: 0.8rem;
}

.chat-top img {
	width: 50px;
	height: 50px;
	object-fit: cover;
	border-radius: 50%;
	margin-right: 10px;
}

.chat-top-right {
	font-size: 20px;
	cursor: pointer;
}

.dots-options {
	position: relative;
}

.dots-options ul {
	position: absolute;
	right: 0;
	top: 40px;
	min-width: 100px;
}

.dots-options li {
	width: 100%;
	padding: 10px;
	background-color: #fff;
	transition: 0.5s;
	cursor: pointer;
	text-align: right;
}

.dots-options li:hover {
	background-color: #eee;
}

.drop-hide {
	display: none;
}

.chat-messages {
	width: 100%;
	height: calc(100% - 160px);
	overflow: scroll;
	padding-top: 10px;
}

.chat-messages::-webkit-scrollbar {
	display: none;
}

.chat-msg-container {
	width: 100%;
	display: flex;
	margin-bottom: 20px;
}

.chat-msg-avatar {
	width: 40px;
	height: 40px;
	position: relative;
	margin-right: 10px;
}

.chat-msg-avatar img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 50%;
}

.chat-msg {
	flex: 1;
	padding: 10px;
	background-color: #f7f7f7;
	border-radius: 8px;
}

.chat-msg.bg-primary p {
	color: #fff;
	font-weight: 200;
}

.msg-typing {
	background-color: #f7f7f7;
	padding: 0px 10px 10px 10px;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	font-size: 1.2rem;
}

.chat-msg h5 {
	margin-bottom: 10px;
	color: #f2994a;
}

.chat-msg p {
	color: #1b1a57;
	font-size: 13px;
}

.chat-msg h6 {
	color: gray;
	font-size: 0.8rem;
	margin-top: 10px;
	width: 100%;
	text-align: right;
}

.chat-form {
	width: 100%;
	height: 80px;
	display: flex;
	border: 1px solid lightgray;
	margin-bottom: 10px;
}

.chat-icon {
	width: 40px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.chat-form input {
	height: 100%;
	flex: 1;
	border: none;
	outline: none;
	background: none;
}

.images-msg {
	align-items: flex-end;
}

.msg-images {
	width: 220px;
}

.img-row {
	width: 220px;
	display: flex;
	overflow: hidden;
}

.msg-images img {
	width: 100%;
	height: 100px;
	object-fit: cover;
	border-radius: 5px;
}

.two-img {
	width: 100%;
	display: flex;
	margin-top: 5px;
	align-items: center;
	justify-content: space-between;
}

.two-img img {
	width: calc(50% - 2.5px);
	object-fit: cover;
}

@media (max-width: 990px) {
	.listing-left,
	.listing-item-right {
		width: 100% !important;
	}
}

@media (max-width: 768px) {
	.sidebar {
		height: fit-content;
	}

	.admin-header {
		flex-direction: column;
	}

	.header-input {
		margin: 20px 0;
		max-width: 90%;
	}
}

/* Admin */
@media (min-width: 1200px) {
	.container{
		max-width: 1175px;
	}
}
@media (max-width: 1200px) {
	.custom-nav-links .nav-item .active {
		border: unset;
	}

	/* .navbar-light .navbar-brand {
    width: 140px;
  } */
	.login-btn-mobile {
		display: flex;
		gap: 15px;
	}
	.login-btn-mobile .social-links-icon,
	.login-btn-mobile .login-btn {
		display: block;
	}
	.nav-social-links {
		display: none;
	}
	.social-links-icon {
		display: none;
	}
	.listing-main .listing-input {
		flex-basis: 31%;
	}
	.contact-us-input div {
		flex-basis: 47%;
	}
}

@media (max-width: 992px) {
	.main-banner-img {
		margin-top: 30px;
	}
	.login-btn button,
	.join-btn button {
		padding: 10px 20px;
		font-size: 16px;
	}

	.header {
		background: #fff;
	}
	.login-btn-desktop {
		display: none;
	}
	.contact-us-image {
		display: none;
	}
	.listing-main .listing-input {
		flex-basis: 47%;
	}
	a.navbar-brand img {
		width: 65px;
	}

	.hero-banner-image {
		display: none;
	}
	.suitable-home-section h2 {
		width: 75%;
	}
	.suitable-home-section .row {
		justify-content: center;
	}
	.suitable-card {
		margin-bottom: 20px;
	}
	.total-members {
		width: 73%;
	}
	.benefits-content {
		width: 100%;
	}
	.benefits-images div {
		flex-basis: 45%;
	}
	.benefits-section .row {
		align-items: center;
	}
	.main-features {
		margin-left: 0px;
		margin-bottom: 50px;
		position: unset;
		width: 100%;
	}
	.order-mobile {
		order: 1;
	}
	.special-features {
		padding-bottom: 80px;
	}
	.main-child-div {
		padding: 50px 0px 50px 0px;
	}
	.slick-next-arrow {
		left: 11%;
	}
	.call-to-action-main {
		width: 100%;
	}
	.address-details {
		display: none;
	}
	.property-detailed {
		width: 46%;
	}

	.family-house {
		width: 90%;
	}

	.Similar-Homes {
		width: 97%;
	}

	.main .slick-initialized .slick-slide {
		margin-left: 19px;
	}

	.left-arrow,
	.right-arrow {
		top: -28%;
	}

	.left-arrow {
		/* left: -22%; */
	}
}
@media (max-width: 767px) {
	.listing-banner {
		min-height: 50vh;
	}
	.listing-banner h2 {
		font-size: 40px;
	}
	.hero-banner h2 {
		font-size: 30px;
	}
	.hero-banner p {
		font-size: 20px;
	}
	.suitable-home-section h2,
	.benefits-content h2,
	.choose-us-content h2,
	.main-child-div h2,
	.feature-title,
	.call-to-action-content h2,
	.filter-results h2,
	.contact-us h2 {
		font-size: 25px;
	}
	.contact-us-form p {
		font-size: 16px;
	}
	.filter-results p {
		font-size: 16px;
		padding-top: 10px;
	}
	.suitable-card {
		margin-bottom: 30px;
	}
	.suitcable-card-mbl{
		margin-bottom: 5px !important;
	}
	.benefits-content p {
		font-size: 20px;
	}
	.total-members {
		width: 44%;
	}
	.benefits-images {
		margin-top: 30px;
	}
	.benefits-images div {
		flex-basis: 48%;
	}
	.choose-us-flex-content h4 {
		font-size: 20px;
	}
	.choose-us-flex-content p {
		font-size: 16px;
	}
	.choose-us-content {
		padding-top: 0px;
	}
	.feature-titles {
		padding-bottom: 0px;
		padding-top: 50px;
	}
	.chose-main-info {
		margin-bottom: 50px;
	}
	.main-prnt-div {
		flex-direction: column;
	}
	.main-child-div {
		width: 100%;
		border-radius: 0px;
	}
	.main-title {
		font-size: 20px;
	}
	.about-feature {
		font-size: 18px;
	}
	.client-review {
		width: 100%;
	}
	.slick-next-arrow {
		left: 15%;
	}
	.call-to-action-content {
		margin-bottom: 30px;
	}
	.footer-content {
		margin-bottom: 30px;
	}
	.footer-link {
		margin-bottom: 30px;
	}
	.left-arrow,
	.right-arrow {
		display: none;
	}
	.footer-contact {
		padding-top: 25px;
	}
	.footer-link h2 {
		margin-bottom: 25px;
	}
	.footer-bottom {
		flex-direction: column;
	}
	.play-store-icon {
		flex-direction: column;
		margin-top: 25px;
		align-items: self-start;
	}
	.footer {
		padding-top: 50px;
	}
}
@media (max-width: 576px) {
	/* .header {
		position: static;
	} */
	.hero-banner-detail {
		padding-top: 0px;
	}
	.hero-banner {
		padding-top: 100px;
	}
	.hero-banner > .container,
	.choose-us-section > .container {
		width: 95%;
	}
	.feed-card {
		flex-direction: column;
		gap: 20px;
		margin-bottom: 15px;
	}
	.feed-card-img {
		width: 100%;
	}
	.custom-nav-links .navbar-nav {
		gap: 0.5rem;
	}
	.feed-card-description {
		width: 100%;
	}
	.order-contact {
		display: block;
	}
	.contact-us-input {
		flex-direction: column;
	}
	.contact-us h2 span {
		display: inline-block;
	}
	.listing-main {
		flex-direction: column;
		max-height: 300px;
	}
	.listing-main .listing-input {
		width: 100%;
	}
	.serving-connters {
		width: 100%;
		left: 0px;
	}
	.total-members {
		width: 100%;
	}
	.total-members span {
		font-size: 30px;
	}
	.benefits-images div {
		flex-basis: 47%;
	}
	.client-detail {
		flex-direction: column;
	}
	.client-img {
		margin-bottom: 20px;
	}
	.slick-prev-arrow,
	.slick-next-arrow {
		bottom: -15%;
	}
	.slick-next-arrow {
		left: 20%;
	}
	.call-subscribe {
		text-align: center;
	}
	.call-subscribe button {
		position: unset;
		margin-top: 15px;
	}
	.suitable-home-section h2 {
		width: 100%;
	}
	.play-store-icon img {
		width: 75%;
	}
}

.filter-results .listing-input {
	width: 350px;
	position: relative;
}
.filter-results .listing-input span {
	position: absolute;
	top: 0;
	left: 7px;
	height: 100%;
	display: flex;
	align-items: center;
}
.filter-results .listing-input span img {
	width: 20px;
}
.filter-results .listing-input select {
	width: 100%;
	padding: 15px 15px 15px 35px;
	font-weight: normal;
	font-size: 16px;
	color: rgba(0, 0, 0, 0.9);
	background: #f8f8f8;
	border: 1px solid #d2d2d2;
	border-radius: 10px;
	outline: none;
	border-radius: 10px;
	background-image: url("../images/select-dropdown-icon.svg");
	background-repeat: no-repeat;
	background-position: right 1rem center;
	background-size: 1em;
	-webkit-appearance: none;
	appearance: none;
	cursor: pointer;
	font-family: "Raleway", sans-serif;
}

.realtor-feats li {
	margin: 10px 0;
	display: flex;
	align-items: center;
}

.realtor-feats li img {
	margin-right: 10px;
	height: 15px;
}

.feed-card-img.realtor-img {
	/* position: relative;
	flex-basis: 30%; */
	min-height: auto;
}

.feed-card-img.realtor-img img {
	width: 100%;
	height: 100% !important;
}

.card-address {
	color: lightgray !important;
	text-decoration: underline;
	width: 100%;
}

.feed-card-description.realtor-desc {
	flex-basis: 80%;
	padding: 20px;
}

.realtor-desc h4 {
	padding-bottom: 0;
}

.realtor-img span {
	position: absolute;
	top: 100%;
	left: 100%;
	width: fit-content;
	transform: translate(-100%, -100%);
}

.realtor-details {
	margin-top: 150px;
}

.details-img-btn {
	/* width: 100%; */
	background-color: #08357a;
	color: #fff;
}

.details-img {
	width: 100%;
	margin-bottom: 20px;
}

.send-btn {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 40px;
}

.send-btn button {
	margin: 0;
}

.feeds-title {
	font-size: 1.4rem;
	margin: 30px 0;
}

.feed-section {
	margin: 20px auto !important;
}

.realtor-container {
	height: fit-content !important;
}

.realtor-feeds-container {
	height: fit-content !important;
	flex: none;
	width: 100%;
}

.realtor-title span {
	background-color: #ffc000;
	font-size: 1rem;
	color: #000;
	padding: 5px 20px;
	margin-left: 100px;
}

.realtor-location {
	display: flex;
	align-items: center;
	margin-bottom: 30px;
}

.realtor-location img {
	height: 20px;
	margin-right: 20px;
}

.realtor-info-title {
	color: #08357a;
	font-size: 1.4rem;
	font-weight: 600;
	margin: 20px 0;
}

.share-btn {
	background-color: #08357a;
	color: #fff;
	margin-bottom: 10px;
	width: 200px;
}

.share-btn:hover {
	color: #fff;
}

.details-btns {
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	flex-direction: column;
}

.details-input {
	/* flex-basis: 23%; */
	position: relative;
	width: 200px;
	margin-right: 0 !important;
}
.details-input span {
	position: absolute;
	top: 0;
	left: 7px;
	height: 100%;
	display: flex;
	align-items: center;
	width: fit-content;
}
.details-input span img {
	width: 20px;
}
.details-input select {
	width: 100%;
	padding: 15px 15px 15px 35px;
	font-weight: normal;
	font-size: 16px;
	color: rgba(0, 0, 0, 0.9);
	background: #f8f8f8;
	border: 1px solid #d2d2d2;
	border-radius: 5px !important;
	outline: none;
	border-radius: 10px;
	background-image: url("../images/select-dropdown-icon.svg");
	background-repeat: no-repeat;
	background-position: right 1rem center;
	background-size: 0.8em;
	-webkit-appearance: none;
	appearance: none;
	cursor: pointer;
	font-family: "Raleway", sans-serif;
}

.views-table {
	margin: 50px auto 0 auto;
}

.views-table table {
	border: 1px solid #d8e7ff;
}

.reject-btn {
	border: 1px solid #d8e7ff;
	padding: 5px 20px !important;
}

.accept-btn {
	border: 1px solid #08357a;
	padding: 5px 20px !important;
	background-color: #08357a;
	margin-left: 5px;
	color: #fff;
}

.accept-btn:hover {
	color: #fff;
}

.deal {
	margin: 50px auto 50px auto;
	background-color: #f8fbff;
	padding: 30px;
	position: relative;
}

.deal-title {
	font-size: 1.3rem;
}

.deal-input {
	display: flex;
	height: 48px;
	background-color: #f2f5f8;
	border-radius: 8px;
	margin-top: 20px;
}

.deal-icon {
	width: 48px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: gray;
}

.deal-input input {
	height: 100%;
	flex: 1;
	background: none;
	padding: 0 10px;
	border: none;
	outline: none;
}

.views-container {
	overflow-y: visible !important;
	height: fit-content;
}

.views-choices span {
	margin-left: 0 !important;
}

.views-choice::before {
	display: inline-block;
	content: "\00a0";
	width: 25px;
	height: 25px;
	margin-right: 10px;
	text-align: center;
	background: #f8fbff;
	border-radius: 5px;
	border: 1px solid #b7b7b7;
}

.views-choices span:nth-child(2) {
	margin-left: 30px !important;
}

.note .note-name {
	font-weight: bolder;
	margin-bottom: 10px !important;
}

.note {
	width: 100%;
	background-color: #f8fbff;
	padding: 10px 20px;
	margin-top: 20px;
	position: relative;
	font-size: 0.9rem;
	color: gray;
}

.note .note-time {
	position: absolute;
	top: 10px;
	right: 10px;
	font-size: 0.8rem;
}

.notes-bottom {
	width: 100%;
	display: flex;
	align-items: center;
	margin-top: 10px;
}

.notes-form {
	flex: 1;
	display: flex;
	align-items: center;
	height: 40px;
	border: 1px solid lightgray;
	background-color: #f9f9f9;
}

.notes-form-icon {
	width: 40px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: gray;
	border: none;
	outline: none;
	cursor: pointer;
	background: none;
}

.notes-form input {
	height: 100%;
	flex: 1;
	border: none;
	outline: none;
	background: none;
	padding: 0 10px;
}

.notes-checkbox {
	margin-left: 40px;
}

.investor {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 15px 0;
}

.investor-img {
	display: flex;
	align-items: center;
}

.investor-img img {
	width: 60px;
	height: 60px;
	object-fit: cover;
	border-radius: 50%;
	margin-right: 10px;
}

.investor-btns button {
	padding: 0 10px !important;
	font-size: 0.8rem;
}

.investor-btns .accept-btn {
	background-color: #252ebb !important;
}
.contractor-details-socail{
	height: 300px !important;
	margin-top: 100px !important; 
}
.contractor-details {
	width: 100%;
	height: 400px;
	background-image: url("../images/Mask.jpg");
	background-size: cover;
	background-position: center;
	margin: auto;
	margin-top: 150px;
	display: flex;
	align-items: flex-end;
	padding: 0;
}

.contractor-details p {
	display: flex;
	align-items: center;
}

.contractor-details p img {
	height: 20px;
	margin-right: 10px;
}

.contractor-details-container {
	background-color: #ffffffcf;
	padding: 20px;
}

.about-title {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.contractor-service {
	margin-bottom: 30px;
}

.contractor-service img {
	width: 100%;
}

.service-title {
	font-size: 1.2rem;
	font-weight: 600;
	margin: 10px 0;
}

.service-price {
	width: 100%;
	font-weight: 600;
	margin: 10px 0;
	font-size: 1.2rem;
}

.service-price span {
	margin-left: 20px;
	font-size: 0.9rem;
	text-decoration: line-through;
}

.service-action {
	width: 100%;
	padding: 3px 0;
	margin-top: 5px;
}

.service-left {
	display: flex;
}

.service-left img {
	height: 144px;
}

.service-desc {
	margin-left: 30px;
}

.service-desc h2 {
	font-size: 1.3rem;
	font-weight: 600;
}

.service-desc-name {
	color: lightgray;
	margin: 10px 0;
}

.service-right ul {
	width: 100%;
}

.service-right li {
	width: 100%;
	display: flex;
	align-items: center;
	color: gray;
	padding: 10px 0;
}

.service-icon {
	font-size: 1.3rem;
}

.service-right li span {
	font-weight: 600;
	margin-left: 10px;
	margin-right: 5px;
	color: #000;
}

.subscription-header {
	margin-top: 97px;
	height: calc(100vh - 97px);
	width: 100%;
	background-image: url('../images/subscription-header.jpg');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	display: flex;
	align-items: center;
	padding: 30px;
  }
  
  .subscription-header-left {
	flex: 1;
  }
  
  .subscription-header-left h1 {
	color: #ffff;
	font-size: 3rem;
	font-weight: lighter;
	margin-bottom: 20px;
  }
  
  .subscription-header-left p {
	font-size: 1.2rem;
  }
  
  .subscription-header-right {
	background-color: #fff;
	padding: 30px;
	border-radius: 5px;
	min-width: 300px;
  }
  
  .subscription-header-right h1 {
	font-size: 2.5rem;
  }
  
  .subscription-header-right h1 span {
	font-size: 1.3rem;
	font-weight: bold;
  }
  
  .text-lightgray {
	color: lightgray;
	margin-bottom: 20px;
  }
  
  .subscription-header-right ul {
	width: 100%;
  }
  
  .subscription-header-right ul li {
	width: 100%;
	padding: 10px 0;
  }
  
  .subscription-card-btn {
	background-color: #08357A;
	width: 100%;
	color: #fff;
	padding: 15px;
	border: none;
	outline: none;
	cursor: pointer;
	border-radius: 5px;
  }
  
  .order-details {
	border: 1px solid lightgray;
  }
  
  .order-title {
	padding: 20px 10px;
	border-bottom: 1px solid lightgray;
	font-weight: bold;
	text-align: center;
  }
  
  .order-details h1 {
	padding: 20px 10px;
	border-bottom: 1px solid lightgray;
	display: flex;
	align-items: center;
	justify-content: space-between;
  }
  
  .order-details-btn {
	width: 90% !important;
	border: none;
	outline: none;
	background-color: #08357A;
	color: #fff;
	border-radius: 5px;
	padding: 10px;
	font-size: .8rem;
  }
  
  .order-p {
	font-size: .9rem;
	padding: 10px;
  }
  button:focus {
    outline: none !important;
  }
  
  .profile-sidebar {
	padding: 32px !important;
  }
  
  .profile-about-title {
	font-size: 1.2rem;
	font-weight: bold;
	margin-top: 15px;
  }
  
  .about-details {
	width: 100%;
  }
  
  .about-details li {
	width: 100%;
	padding: 5px 0;
  }
  
  .about-details-btn {
	width: 100% !important;
	border: none;
	outline: none;
	background-color: #08357A;
	color: #fff;
	border-radius: 5px;
	padding: 10px;
	font-size: .8rem;
	margin: 20px 0;
  }
  
  .about-label {
	font-size: .9rem;
	margin-bottom: 5px;
  }
  
  .location-text {
	font-size: .9rem;
  }
  
  .associated-property-img {
	width: 60px;
	height: 60px;
	object-fit: cover;
	border-radius: 5px;
  }
  
  .latest-feeds-title {
	font-size: 1.3rem;
	margin-bottom: 20px;
  }



  
.related-prop-img {
	width: 250px !important;
}
.social-media-post-profile-image {
    border-radius: 50%;
    height: 56px !important;
    width: 56px !important;
}
.social-media-post-like-image {
    border-radius: 50%;
	height: 33px !important;
    width: 33px !important;
}

.social-media-post-proplistng-image {
    height: 140px !important;
    width: 140px !important;
}

/* .dropdown-menu {
   
    left: -135px !important;
} */

.property-add-btn {
	width: 100%;
    background-color: #fff;
    border: 3px solid #08357a;
    color: #08357a;
}
.profile-add-btn {
    width: 100%;
    background-color: #fff;
    border: 1px solid #c0dbff;
    color: #08357a;
}
.profile-add-btn:hover {
    border: 1px solid #4486dd;
}
a.btn.active.profile-add-btn {
    border: 1px solid #4486dd;
}
.edit-icon {
	display: inline-block;
    width: 20px;
    height: 20px;
    position: absolute;
    margin-left: -5px;
	cursor: pointer;
	margin-top: -10px;
	
  }
  .hiddenfileinput{
	color: white;
    width: 1%;
    height: 10% !important;
    opacity: 0;
    position: absolute;
	margin-top: -10px;
	cursor: pointer;
  }
  .house-img {
	width: 345px !important;
}
.signupcheckbox{
	height: 13px !important;
	margin-right: 5px;
}


.container-fluid{
	padding: 0px !important;
}
.customnavbar{
	padding: 0px !important;
}

.headernavbarimage img {
	margin-bottom: -22px;
    margin-right: 12px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.headernavbarimage{
	margin-bottom: -15px;
    margin-top: -12px;
}
#nav-dropdown-dark-example-new {
	/* margin-bottom: 0px; */
	/* margin-top: -15px; */
}
.tutorialbtn {
	padding: 5px 13px;
    background: #08357a;
    border-radius: 8px;
    border: solid;
    outline: none;
    color: #fff;
    transition: all 0.3s ease-out;
}
.tutorialbtn:hover {
	background: #fff;
	color: #08357a;
	border-style:  solid;
}
.Ralewayfamily {
	font-family: "Raleway", sans-serif !important;
}
.btn-circle{
	border-radius: 50%;
}
.blue-btn:hover{
	color: #fff !important;
	text-decoration: none !important; 
}
.sendbtn{
	border: none;
}

.profileEditImage{
	margin-right: 15px;
	width: 100px;
}

.yes-no-containernew{
	/* display: block; */
}
.signup-input select {
    width: 100%;
    height: 40px;
    background-color: #e8f0fe;
    border: none;
    outline: none;
    border-radius: 6px;
    padding: 5px;
}

.reducedescription {
	height: 65px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
}
.reducedescriptionnotificationpanl{
	height: 56px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
}
.reducedescriptionthreeclamp {
    height: 46px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

img:hover {
    opacity: 0.9;
    cursor: pointer;
}

.pagesheading{
	font-weight: bold;
    font-size: 20px;
    margin: 10px;
}
.detail-image-new{
	object-position: 50%;
    object-fit: cover;
    height: 200px;
}
.detail-image-main{
	object-position: 50%;
    object-fit: cover;
    height: 400px;
}
.socialicon{
	width: 30px;
	height: 30px;
	text-align: center;
	padding: 8px;
}

.css-uo2dfc-Popover {
    padding: 40px !important;
}

.reactour__popover  span {
	background: #08357a !important;
    padding: 1px 10px !important;
    height: 2.2em !important;
}

.css-1koqtpg-Navigation {
    
    background: #08357a !important;
}

[aria-label='Close Tour'] {
    
    top: 13px  !important;
    right: 12px  !important;
}



ol.progtrckr {
    margin: 0;
    padding-bottom: 1.2rem;
    list-style-type: none;
    display: table;
    table-layout: fixed;
    width: 100%;
}
ol.progtrckr li.progtrckr-doing {
    color: black;
    border-bottom: 4px solid #08357a;
	
}
ol.progtrckr li {
	/* display: inline-block; */
    text-align: center;
    line-height: 4.5rem;
    display: table-cell;
    /* padding: 0 5.4rem; */
    width: 50%;
    cursor: pointer;
}
ol.progtrckr > li > span {
    font-size: 14px;
}

ol.progtrckr li.progtrckr-doing:before {
    content: "\2022";
    color: white;
    background-color: #08357a;
    width: 1.2em;
    line-height: 1.2em;
    border-radius: 1.2em;
}
ol.progtrckr li:before {
    position: relative;
    bottom: -4rem;
    float: left;
    left: 50%;
}

ol.progtrckr li.progtrckr-todo:before {
    content: "\039F";
    color: silver;
    background-color: white;
    width: 1.2em;
    line-height: 1.4em;
}
ol.progtrckr li:before {
    position: relative;
    bottom: -4rem;
    float: left;
    left: 50%;
}
ol.progtrckr li.progtrckr-todo {
    color: silver;
    border-bottom: 4px solid silver;
}
ol.progtrckr li.progtrckr-done:hover:before {
    color: #0FA0CE;
}
ol.progtrckr li.progtrckr-done {
    color: black;
    border-bottom: 4px solid #08357a;
}
ol.progtrckr li.progtrckr-done:before {
    content: "\2713";
    color: white;
    background-color: #08357a;
    width: 1.2em;
    line-height: 1.2em;
    border-radius: 1.2em;
}

/* .dropdown-item  > span{
    
    font-weight: 400;
    margin: 0;
    font-size: 16px;
} */

.selected_type {
	border: #8d8383 2px solid;
}

.choose-us-content-property-details {
    padding-block: 10px !important;
}

.dobdatepicker .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root:hover {
    background-color: white !important;
}

.file-field-image-container-main {
    height: 70px !important;
}

.file-field-image-container {
    height: 60px !important;
    overflow-y: auto !important;
    overflow-x: auto !important;
    overflow: scroll;
}
::-webkit-scrollbar {
	width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
	background: #f1f1f1; 
	box-shadow: inset 0 0 5px grey;
	border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
	background: #888; 
	border-radius: 10px;

  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
	background: #555; 
  }

.view-images-container {
    height: 80px !important;
    overflow-y: auto !important;
    overflow-x: auto !important;
    overflow: scroll;
}
.file-field-image-container-profile {
    height: 60px !important;
    overflow-y: auto !important;
    overflow-x: auto !important;
    overflow: scroll;
}

.file-field-image-container-cover {
    height: 25px !important;
    bottom: 0px;
    padding-top: 2px;
    overflow-y: auto !important;
    overflow-x: auto !important;
    overflow: scroll;
    background-color: #ffffffcf !important;
}

.file-field-image-container h3 {
	height: 100% !important;
}

.file-field-image-container input {
	height: 40% !important;
    width: 80%;
	margin-top: 15px;
}



.chekboxdiveditprfile{
	padding: 0  15px !important;
}
.user_buyer{
	border: 5px solid  #006bf8;
}

.user_bg_buyer{
	background-color:  #006bf8;
	color: white;
	
}

.user_seller{
	border: 5px solid  #989898;
}

.user_bg_seller{
	background-color:   #989898;
	color: white;
}
.user_Real_Estate_Professional{
	border: 5px solid  #f9f126;
}

.user_bg_Real_Estate_Professional{
	background-color:   #f9f126;
	color: black;
}
.user_contractor{
	border: 5px solid  #b55b04;
}

.user_bg_contractor{
	background-color:  #b55b04;
	color: white;
}

.user_investor{
	border: 5px solid  #67b7db;
}
.user_bg_investor{
	background-color:  #67b7db;
	color: white;
}
.user_affiliates{
	border: 5px solid  #e20000;
}
.user_bg_affiliates{
	background-color:  #e20000;
	color: white;
}
.user_border_buyer{
	border-top: 5px solid  #006bf8;
}
.user_border_seller{
	border-top: 5px solid  #989898;
}
.user_border_Real_Estate_Professional{
	border-top: 5px solid  #f9f126;
}
.user_border_contractor{
	border-top: 5px solid  #b55b04;
}

.user_border_investor{
	border-top: 5px solid  #67b7db;
}
.user_border_affiliates{
	border-top: 5px solid  #e20000;
}
/* .animatefadein{
	position:relative;
	animation:animatetop 0.4s
} */
i.fa.fa-diamond {
    top: -44px;
    left: 1px;
    color: #b9b900;
    position: relative;
}
.signup-form  .animatefadein {
    margin-top: 25px;
    font-size: 21px;
    /* text-align: center; */
    animation: fadein 2s;
    -moz-animation: fadein 2s; /* Firefox */
    -webkit-animation: fadein 2s; /* Safari and Chrome */
    -o-animation: fadein 2s; /* Opera */
}
@keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-moz-keyframes fadein { /* Firefox */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-o-keyframes fadein { /* Opera */
    from {
        opacity:0;
    }
    to {
        opacity: 1;
    }
}

.fa-check{
	/* border: 1px solid;
    padding: 8px;
    border-radius: 50%; */
}
.fa-xmark {
    /* border: 1px solid;
    padding: 8px;
    border-radius: 50%; */
}
.checklist li{
	
	padding: 3px;
}
.checklist li i{
	
	margin-right: 3px;
}

.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root:hover {
    background-color: #08357a !important;
}

.dobdatepicker .MuiInputAdornment-root .MuiButtonBase-root {
    margin-top: 0px !important; 
    padding-right: 12px  !important;
}


.display-4-custom {
	font-size: 20px;
    font-weight: 200;
    line-height: 64px;
    margin-top: 15px;
    color: #0000ff;
    cursor: pointer;
}
.display-4-custom-new {
	font-size: 30px;
    font-weight: 300;
    line-height: 45px;
    cursor: pointer;
    width: auto !important;
    height: auto !important;
}

.adduserimg{
	margin-left: -8px;
}

.fs-15 {
	font-size: 15px;
}
.social-btn{
	width: 42px;
    height: 42px;
    background-color: white;
    border: none;
}
.social-btn:focus {
	outline: none !important;
}
button.react-share__ShareButton {
    margin-left: 5px;
}
.react-share__ShareButton:focus {
	outline: none !important;
}

[aria-label='Go to next step'] svg {
    display: none !important;
}
[aria-label='Go to prev step'] svg {
	display: none !important;
}

[aria-label='Go to next step']:before {
    content: 'Next';
}
[aria-label='Go to prev step']:before {
    content: 'Previous';
}
.css-1yleuww-DefaultArrow{
	display: none !important;
}

[aria-label='Go to prev step']{
	padding: 5px !important;
    background: #08357a !important;
    border-radius: 8px !important;
    border: solid !important;
    outline: none !important;
    color: #dedede !important;
    transition: all 0.3s ease-out !important;
}
[aria-label='Go to next step']{
	padding: 5px !important;
    background: #08357a !important;
    border-radius: 8px !important;
    border: solid !important;
    outline: none !important;
    color: #dedede !important;
    transition: all 0.3s ease-out !important;
	
}
[aria-label='Close Tour']{
	width: 37px !important;
}
[aria-label='Close Tour']::after{
	content: 'Skip';
    padding: 5px !important;
    margin: 5px;
    background: #08357a !important;
    border-radius: 7px !important;
    border: 0px #dedede solid !important;
    outline: none !important;
    color: #dedede !important;
    transition: all 0.3s ease-out !important;
	
}
.css-11p5tv2-Close svg{
	display: none !important;
}
.diamond-icon{
	width: 20px;
    z-index: 1111;
    margin-bottom: -16px;
}

a.profiledropdownmenu {
    display: block;
    color: black;
	padding: 0.25rem 1.5rem;
    /* text-align: center; */
}
[aria-label="Close Tour"] svg {
    display: none;
}

[aria-label='Go to step 1'] {
    background: #08357a !important;
}
[aria-label='Go to step 2'] {
    background: #08357a !important;
}
[aria-label='Go to step 3'] {
    background: #08357a !important;
}
[aria-label='Go to step 4'] {
    background: #08357a !important;
}
[aria-label='Go to step 5'] {
    background: #08357a !important;
}
[aria-label='Go to step 6'] {
    background: #08357a !important;
}
[aria-label='Go to step 7'] {
    background: #08357a !important;
}
[aria-label='Go to step 8'] {
    background: #08357a !important;
}
[aria-label='Go to step 9'] {
    background: #08357a !important;
}
.MuiInputAdornment-root .MuiButtonBase-root {
    margin-top: -23px !important;
    padding-right: 33px !important;
}
.page-link {
  
    padding: 0.5rem 0.55rem !important;
}

/* p.mb-2::before {
	content: '✓';
    margin-right: 6px;
    font-weight: bold;
} */


@media   ( max-width: 575.98px) and  (min-width: 300px  ) {
    .w-xs-100 {
        width: 100% !important;
    }

    .w-xs-75 {
        width: 75% !important;
    }

    .w-xs-50 {
        width: 50% !important;
    }

    .w-xs-25 {
        width: 25% !important;
    }

    .h-xs-100 {
        height: 100% !important;
    }

    .h-xs-75 {
        height: 75% !important;
    }

    .h-xs-50 {
        height: 50% !important;
    }

    .h-xs-25 {
        height: 25% !important;
    }
}
/* Small devices (landscape phones, 576px and up)*/
@media   ( max-width:767px) and  (min-width: 576px  ) {

    .w-sm-100 {
        width: 100% !important;
    }

    .w-sm-75 {
        width: 75% !important;
    }

    .w-sm-50 {
        width: 50% !important;
    }

    .w-sm-25 {
        width: 25% !important;
    }

    .h-sm-100 {
        height: 100% !important;
    }

    .h-sm-75 {
        height: 75% !important;
    }

    .h-sm-50 {
        height: 50% !important;
    }

    .h-sm-25 {
        height: 25% !important;
    }
}



/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
    .w-md-100 {
        width: 100% !important;
    }

    .w-md-75 {
        width: 75% !important;
    }

    .w-md-50 {
        width: 50% !important;
    }

    .w-md-25 {
        width: 25% !important;
    }

    .h-md-100 {
        height: 100% !important;
    }

    .h-md-75 {
        height: 75% !important;
    }

    .h-md-50 {
        height: 50% !important;
    }

    .h-md-25 {
        height: 25% !important;
    }
}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
    .w-lg-100 {
        width: 100% !important;
    }

    .w-lg-75 {
        width: 75% !important;
    }

    .w-lg-50 {
        width: 50% !important;
    }

    .w-lg-25 {
        width: 25% !important;
    }

    .h-lg-100 {
        height: 100% !important;
    }

    .h-lg-75 {
        height: 75% !important;
    }

    .h-lg-50 {
        height: 50% !important;
    }

    .h-lg-25 {
        height: 25% !important;
    }
}

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
    .w-xl-100 {
        width: 100% !important;
    }

    .w-xl-75 {
        width: 75% !important;
    }

    .w-xl-50 {
        width: 50% !important;
    }

    .w-xl-25 {
        width: 25% !important;
    }

    .h-xl-100 {
        height: 100% !important;
    }

    .h-xl-75 {
        height: 75% !important;
    }

    .h-xl-50 {
        height: 50% !important;
    }

    .h-xl-25 {
        height: 25% !important;
    }
}
.associated-property-img-profile{
 width: 100%;
    height:100px;
    object-fit: cover;
}

.profilephotos-post img {
    /* width: 100%;
    height:100px; */
    object-fit: cover;
}

.packageborder{
	padding: 15px;
    border: 1px solid #8f8c8c;
    border-radius: 10px;
	height: 100%;

}

.profileimg .dropdown-toggle::after {
  display: none !important; 
}

.zindex{
	z-index: 99;
}
.profilesecionborder{
	border: 1px solid #b9dcff;
    padding: 10px;
}
.imgsectionborder {
	border: 1px solid #b9dcff;
    padding: 22px;
}

.profileuploadimage{
	border: 2px dotted #adc0d9;
    padding: 3px 3px 0px 3px;
}
.edit-btn-sm {
	width: 50px !important;
}